import api from "./axios-client";
import local_api from "./axios-local-client"
import store from "@/store";
export function setup(){

    api.interceptors.request.use(

        config => {
            const token = store.getters['auth/getToken'];
            store.dispatch('app/set_loader', true)
            if (token) {
                config.headers.common["Authorization"] = token;
            }

            return config;
        },

        error => {
            return Promise.reject(error);
        }
    );
    local_api.interceptors.response.use(
        response => {
            store.dispatch('app/set_loader', false)

            return response;
        },
        error => {
            const original_request = error.config;
            if (!error.response){
                store.dispatch('app/set_connectivity', false)
            }
            if(error.response.status === 401){
                store.dispatch('auth/token').then(() => {
                    return api(original_request);
                }).catch(error => {
                    console.log(error)
                });
            }
            return Promise.reject(error.response)
        }
    );
    api.interceptors.response.use(
        response => {
            store.dispatch('app/set_loader', false)
            store.dispatch('app/set_connectivity', true)
            return response;
        },
        error => {
            const original_request = error.config;
            console.log('err', error)
            if (!error.response){
                store.dispatch('app/set_connectivity', false)
            }
            if(error.response.status === 401){
                store.dispatch('auth/token').then(() => {
                    return api(original_request);
                }).catch(error => {
                    console.log(error)
                });
            }
            if (error.response.status === 403){
                store.dispatch('auth/refresh_session_token')
                    .then(() => {
                        return api(original_request);
                    }).catch(error => {
                        console.log(error);
                })
            }
            return Promise.reject(error.response)
        }
    )
}
