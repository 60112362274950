import {SET_ACTIVE_FILTERS_COUNT, SET_DIALOG_STATUS, SET_FILTERS, SET_PRESET_FILTERS} from './mutation-types';
export default {

    [SET_ACTIVE_FILTERS_COUNT] (state, count) {
        state.active_filters_count = count;
    },
    [SET_DIALOG_STATUS] (state, status){
        state.is_dialog_active = status;
    },
    [SET_FILTERS] (state, filters) {
        state.filters = filters;
    },
    [SET_PRESET_FILTERS] (state, presets) {
        state.preset_filters = presets;
    }
};
