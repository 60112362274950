/** @var { DashboardState } */
const STATE = {
    home_data: null,
    destinations: null,
    selected_destination: null,
    selected_experience: null,
    attractions: null,
    activities: null,
    search_results: null,
    pills: null,
    filters: null,
    search_term: null
};

export default STATE;
