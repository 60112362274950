/**
*
* 
* @param { DealState } state
* @return { Object }
*/
export function deal_detail(state) {
    return state.deal_detail;
}

export function tour_reviews(state){
    return state.tour_reviews;
}