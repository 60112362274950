<template>
  <div>
    <div class="merch-acc-block">
      <span class="merc-block-title">Location:</span>
      <p>{{ info.location }}</p>
    </div>
    <div class="merch-acc-block">
      <span class="merc-block-title">Tour code:</span>
      <p>{{ info.tourcode }}</p>
    </div>
    <div class="merch-acc-block">
      <span class="merc-block-title">Voucher Info:</span>
      <p>{{ info.voucherRequirements }}</p>
    </div>
    <div v-for="(detail, i) in info.details" :key="i" class="merch-acc-block">
      <span class="merc-block-title">{{ detail.section_title }}</span>
      <ul>
        <li v-for="(data, i) in detail.data" :key="i"> <p> {{ data }} </p> </li>
      </ul>
    </div>
</div>
</template>
<script>
export default {
  name: "ImportantInfo",
  props: {
    info: Object
  },
}
</script>

<style scoped>

</style>