import LocationCard from "../../components/LocationCard/LocationCard";
import AlphabetBar from "../../components/AlphabetBar/AlphabetBar";
import ListingBlockComponent from "../../components/ListingBlockComponent/ListingBlockComponent";
import CitySearchResult from "../../components/CitySearchResult/CitySearchResult";
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import _ from 'lodash';
// @vue/component
export default {
    name: 'Dashboard',

    components: {
        LocationCard,
        AlphabetBar,
        ListingBlockComponent,
        SearchComponent,
        CitySearchResult
    },
    inject: {
        theme: {
            default: { isDark: false },
        },
    },

    mixins: [],

    props: {},

    data () {
        return {
            tab: null,
            items: ['Popular Locations', 'All Locations'],
            isSearching: false,
            results: [],
            loaded: false,

        }
    },

    computed: {
        destinations(){
            let destinations = this.$store.getters['dashboard/get_destinations_data']
            if (!destinations)
                return ;
            return destinations;
        }
    },

    watch: {},

    created () {
    },

    methods: {

        filter_destinations: function (value) {
            this.results = [];
            this.isSearching = value.length > 0;
            if (!this.isSearching){
                return ;
            }
            this.tab = 1;
            let destinations = this.destinations.all_destinations;
            this.extract_cities(destinations, value);
        },
        setSelectedDestination: function (destination) {
            this.$store.dispatch('dashboard/set_selected_destination', destination);
            this.$router.push('/home');
        },

        extract_cities: function (value, term){
            for (let i = 0 ; i< value.length; i++){
                let country = value[i];
                for (let j = 0 ; j < country.destinations.length ; j++){
                    let destination = country.destinations[j];
                    let cities = destination.cities;
                    for (let k = 0; k < cities.length; k++) {
                        let city = cities[k];
                        let name = _.toLower(city.name);
                        if (name.includes(_.toLower(term))){
                            this.results.push(city);
                        }
                    }
                }
            }
        },


    }
}
