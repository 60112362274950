import Client from './clients/axios-client'

const resource = '/day-planner-v'


export default {
    get_recommended_plans : function (payload){
        return Client.post(`${resource}/get-recomended-plans`, payload)
    },
    create_day_plan: function (payload){
        return Client.post(`${resource}/create-day-plan`, payload)
    },
    get_plan_details: function (payload){
        return Client.post(`${resource}/get-plan-detail`, payload)
    },
    delete_day_plan: function (payload){
        return Client.post(`${resource}/delete-day-plan`, payload)
    },
    update_day_plan: function (payload){
        return Client.post(`${resource}/update-day-plan`, payload)
    }
}