/** @const { AuthState } */
const STATE = {
    user: null,
    token: null,
    isUserLoggedIn: false,
    tickets: null,
    auth_data: {
        __t: null,
        __user_id : null,
        __session_id: null
    }
};

export default STATE;
