// @vue/component
import Ticket from "@/components/Ticket/Ticket";
import commonMixins from "../../mixins/commonMixins";
export default {
    name: 'Tickets',

    components: {
        Ticket
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {

        }
    },

    computed: {
        tickets: function () {
            return this.$store.getters['auth/getTickets']
        },
        user: function () {
            return this.$store.getters['auth/getUser']
        }
    },

    watch: {},
    mounted () {
        this.get_tickets()
    },

    methods: {
         get_tickets(){
             let headers = this.get_form_data_with_auth()
             this.$store.dispatch('auth/get_tickets_data', headers)
         }
    }
}
