<template>
  <div class="crlWrap merch-nearby-outer">
    <div class="carousel_section exp-city">
      <div class="head">
        <h2 class="left title"> {{ nearby.section_title }} </h2>
        <span v-if="view_all" class="right view_link">View All</span>
      </div>
      <div class="car_slid_wrap">
        <VueSlickCarousel v-bind="settings" >
          <div class="cr_tab item" v-for="(tile ,i) in nearby.nearby" :key="i"  v-ripple>
            <div class="img_holder">
              <v-image  :src="tile.image" alt="#" ratio="2"></v-image>
              <div @click="move_to_detail(tile)" class="cr_img_overlay">
                <div class="table">
                  <div class="table-cell">
                    <div v-if="tile.distance" class="cr_btm_dist_sec clearfix">
                      <mark class="left gredient2"> {{ tile.distance }} </mark>
                    </div>
                  </div>
                </div>
              </div>
              <div class="heart_overlay">
                <div class="table">
                  <div class="table-cell">
                    <div @click="$store.dispatch('wishlist/toggle_wishlist_item', tile)" class="cr_tp_off_sec">
                      <a href="javascript:void(0)" :class="get_wishlist_class(tile)? 'btn cr_fav_icon':'cr_fav_icon_fill'"></a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div @click="move_to_detail(tile)" class="text_holder ">
              <h2 class="fix_height">{{ tile.display_name }} </h2>
              <div class="reviews_price_wrap">
                <div class="rating_sec_outer left">
                  <div class="rating">
                    <span class="rating_star" style="width:90%"></span>
                  </div>
                  <p>{{ tile.rating_count_text }}</p>
                </div>
                <div class="price_dis_sec right">
                  <del class="old_price"> {{ tile.currency }} {{ tile.rr_price }}</del>
                  <span class="price"> <i class="ins_confirm_icon"></i>{{ tile.currency }} <b class="cur"></b>{{ tile.price }}</span>
                  <span class="dis_price"> {{ tile.price_off_text }}</span>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import commonMixins from "../../mixins/commonMixins";

export default {
  name: "SliderLarge",
  components : {
    VueSlickCarousel
  },
  props: {
    nearby: Object,
    title: String,
    view_all: Boolean
  },
  mixins: [commonMixins],
  data() {
    return {

      settings: {
        "dots": false,
        "autoplay": false,
        "dotsClass": "slick-dots slider-dots",
        "edgeFriction": 0.0,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrow":false,
      },
    }
  },

}
</script>
<style>
.heart_overlay {
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  position: absolute;
  text-align: center;
  background: none;
}

.heart_overlay .table-cell {
  width: 100%;
  padding: 16px;
  vertical-align: top;
}
</style>