<template>
  <div class="info-list-detail">
    <div class="merch-acc-block">
      <ul class="amianies-list-otr">
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/with-view.png" alt="#" > </span>
          <b>With A View</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/price-rang.png" alt="#"> </span>
          <b>Price Range</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/open-late.png" alt="#"> </span>
          <b>Open Late</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/Hubbly-Bubbly.png" alt="#"> </span>
          <b>Hubbly & Bubbly</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/with-view.png" alt="#" > </span>
          <b>With A View</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/price-rang.png" alt="#"> </span>
          <b>Price Range</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/open-late.png" alt="#"> </span>
          <b>Open Late</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/Hubbly-Bubbly.png" alt="#"> </span>
          <b>Hubbly & Bubbly</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/with-view.png" alt="#" > </span>
          <b>With A View</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/price-rang.png" alt="#"> </span>
          <b>Price Range</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/open-late.png" alt="#"> </span>
          <b>Open Late</b>
        </li>
        <li class="amianies-list ">
          <span class="flex-ver-hor-center"> <img src="../../assets/images/Hubbly-Bubbly.png" alt="#"> </span>
          <b>Hubbly & Bubbly</b>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  name: "Amenities"
}
</script>

<style scoped>

</style>