<template>
  <div class="rating_sec_outer left">
    <stars :stars="rating"></stars>
    <p> {{ text }}</p>
  </div>
</template>

<script>
    export default {
        props: {
          rating: null,
          text: null
        },
    };
</script>
