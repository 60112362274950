import VuexPersistence from 'vuex-persist'
const storageKey = 'ego-vis-c';


// How to use it?
const vuexLocal = new VuexPersistence({
    key: storageKey,
})


export default vuexLocal.plugin