<template>
  <div class="no-offer-view-inner">
    <span class="offer-icon">
      <img :src="img" alt="no-offer-icon" />
    </span>
    <h3 style="color: black">{{ title }}</h3>
    <p style="color: black">
      {{ description }}
    </p>
  </div>
</template>

<script>
    export default { props: ['title', 'description', 'img'], };
</script>
