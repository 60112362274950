import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async function (to, from, next){
  store.dispatch('app/set_connectivity', true)
  let query = to.query;
  store.dispatch('app/set_loader', true)
  store.dispatch('backbutton/push_to_pages', to)
  if (query){
    if (query['myticket'] === 'deeplink')
      router.push('/tickets')
  }
  store.dispatch('app/set_menu_state', false)
  if (to.fullPath === from.fullPath) {
    next('/home');
  }
  next();
})
router.afterEach(() => {
  store.dispatch('app/set_loader', false)
})
router.onError(() => {
  router.push('/home')
})
export default router
