<template>
  <div>
    <p v-if="text.length > min">
      <span>
        {{ min_text() }}
      </span>
      <span v-if="is_read_more">
        {{ last_text() }}
      </span>

      <a href="#" @click="is_read_more = !is_read_more">Read More</a>
    </p>
    <p v-else>
      {{ text }}
    </p>
  </div>

</template>

<script>
export default {
  name: "ReadMore",
  props: ['text', 'min'],
  data() {
    return {
      is_read_more: false,
    }
  },
  methods: {
    min_text() {
        return this.text.substring(0,this.min)
    },
    last_text(){
      return this.text.substring(this.min, this.text.length);
    }
  },
}
</script>

<style scoped>

</style>