<template>
  <div>
    <GmapMap
        :center="getProperty(outlets[0])"
        :zoom="zoom"
        :options="{ scrollWheel:false, zoomControl:false, scaleControl:false }"
        map-type-id="terrain"
        :style="hw"
        ref="mapRef"
    >
      <GmapMarker
          :key="index"
          v-for="(marker, index) in outlets"
          :position="getProperty(marker)"
          :clickable="true"
          :draggable="false"
          :icon="pinOptions"
      >
      </GmapMarker>
    </GmapMap>
</div>
</template>
<script>
import PinIcon from '@/assets/images/pin_9_active.png'
import PinIcon2 from '@/assets/images/pin_active_10.png'
export default {
  name: "MapWithMarkers",
  props: ['outlets', 'hw', 'zoom'],
  data() {
    return {
      PinIcon,
      pinOptions: {
        url: PinIcon2,
        size: {width: 60, height: 90, f: 'px', b: 'px',},
        scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
      },
      paths: []
    }
  },
  methods: {
    getProperty(item) {
      if (Object.hasOwnProperty.call(item,'latitude') && Object.hasOwnProperty.call(item,'longitude')) {
        return { lat: item.latitude, lng: item.longitude }
      } else if(Object.hasOwnProperty.call(item,'lat') && Object.hasOwnProperty.call(item,'lng')) {
        return { lat: item.lat, lng: item.lng }
      } else if (item.geometry){
        return { lat: item.geometry.location.lat(), lng: item.geometry.location.lng() }
      }
    },

  },
  beforeMount() {
    for (let item in this.outlets){
      let path = {
        lat: this.outlets[item].lat,
        lng: this.outlets[item].lng
      }
      this.paths.push(path)
    }
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      map.panTo(this.getProperty(this.outlets[0]))
    })
  }
}
</script>

<style scoped>
.vue-map-container,
.vue-map-container .vue-map {
  width: 100%;
  height: 100%;
}
</style>