<template>
  <div class="info-list-detail" style="display: block">

    <div class="merch-acc-block">
      <span class="merc-block-title">{{ schedules.section_title }}</span>
      <p>Your centrally located Dubai hotel</p>
    </div>
    <div v-for="(schedule, index) in schedules.tour_schedule_features" :key="index" class="merch-acc-block">
      <span class="merc-block-title">{{ schedule.title }}</span>
      <p>
        {{ schedule.value }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Schedule",
  props: {
    schedules: Object
  },
}
</script>

<style scoped>

</style>