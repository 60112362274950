<template>
  <div class="full_size_div">
    <div v-scroll.self="onScrollActivities" v-if="activities && activities.length > 0"  id="activities_container" class="thing-common-class overflow-auto full_size_div d-block">
      <div v-for="(activity, index) in activities"  :key="index">
        <ActivityCard v-if="is_planner" subtype="planner" v-on:clicked="move_to_detail"  :data="activity" type="activities"></ActivityCard>
        <ActivityCard v-else v-on:clicked="move_to_detail"  :data="activity" type="activities"></ActivityCard>
      </div>
    </div>
    <!-- Start Bottom Navigation Bar -->
    <div class="scr-btm-overlay">
      <BottomNavigationBar>
        <GradientButton  :badge="activeFilterCount" @click.native="dialog = true;" title="Filter" :img="filterIcon"></GradientButton>
      </BottomNavigationBar>
      <div v-if="is_planner" class="scr-btm-Buttons btm-full-btn">
        <router-link to="/planner/create-plan/create" class="buttons gredient2"><span> View Day Plan: {{ plan_draft_count }} Items selected  </span></router-link>
      </div>
    </div>
    <!-- End Bottom Navigation Bar -->

    <!-- No-Offer Screen / Component -->
    <div v-if="activities && activities.length <= 0" class="no-offer-view">
      <NoOfferComponent class="no-offer" ></NoOfferComponent>
    </div>
    <v-dialog v-model="dialog">
      <Filters @reset="sort_by_filters = false;" :preset="sort_by_filters" @close="closeDialog"></Filters>
    </v-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import BottomNavigationBar from "../BottomNavigationBar/BottomNavigationBar";
import GradientButton from "@/components/GradientButton";
import NoOfferComponent from "@/components/NoOfferComponent/NoOfferComponent";
import ActivityCard from "../../components/ActivityCard/ActivityCard";
import filterIcon from "../../assets/images/filter-icon.png";
import Filters from "@/components/Filters/Filters";
import commonMixins from "../../mixins/commonMixins";

export default {
  name: "Activities",
  components: {
    BottomNavigationBar,
    GradientButton,
    NoOfferComponent,
    ActivityCard,
    Filters
  },
  computed: {
    activities() {
      return this.activities_data ? this.activities_data.things_to_do : null;
    },
    presetFilters: function (){
      return JSON.parse(JSON.stringify(this.$store.getters['filters/get_preset_filters']))
    },
    is_planner: function(){
      return this.$route.name === 'planner-activities';
    },
    plan_draft_count: function (){
      return this.$store.getters['planner/plan_draft'].length;
    },
  },
  mounted() {
    if (this.presetFilters)
      this.sort_by_filters = this.presetFilters;
    this.$nextTick(() => {
      this.get_list()
      this.get_filters()
      this.$store.dispatch('filters/reset_filters')
    })
  },
  mixins: [commonMixins],
  data() {
    return {
      activeFilterCount: 0,
      loaded: false,
      dialog: false,
      offset: 0,
      deal_type: 'tours',
      category_id: 3,
      page_size: 20,
      sort_by: 'sort_by_near_by',
      filterIcon: filterIcon,
      activities_data: null,
      sort_by_filters: null
    }
  },
  methods: {
    closeDialog: function(data){
      this.dialog = false;
      this.activities_data = null;
      this.activities_data = data.data;
      this.activeFilterCount = data.count;
      this.offset = 0;
    },

    onScrollActivities () {
      if (this.activeFilterCount > 0 || !this.activities_data)
        return;
      let obj = $('#activities_container')
      if( obj.scrollTop() >= (obj[0].scrollHeight - obj[0].offsetHeight)) {
        this.offset += 10;
        this.get_list()
      }
    },
    set_activities: function(activities){
      if (!this.activities_data)
        this.activities_data = activities;
      if (this.activities_data.things_to_do && activities.things_to_do){
        this.activities_data.things_to_do = [...this.activities_data.things_to_do, ...activities.things_to_do]
      }
      else
        this.activities_data = activities;
    },
    get_filters: function(){
      let common_data = this.get_form_data()
      common_data.set('deal_type', 'tours')
      common_data.set('category_id', 3)
      this.$store.dispatch('dashboard/get_filters', common_data)
    },
    get_list(){
      let common_data = this.get_form_data()
      common_data.set('offset', this.offset);
      common_data.set('sort_by', this.sort_by);
      common_data.set('page_size', this.page_size);
      common_data.set('category_id', this.category_id);
      if (this.presetFilters && Object.hasOwnProperty.call(this.presetFilters,'api_param_name')){
        common_data.set(this.presetFilters.api_param_name, this.presetFilters.key)
        common_data.set('deal_type', this.deal_type);

        //  TODO replace this in case more than one preset filters added
        this.activeFilterCount = 1;
        this.$store.dispatch('filters/reset_preset_filters');
      } else {
        this.activeFilterCount = 0;
        common_data.set('deal_type', this.deal_type);
      }
      this
          .$store
          .dispatch('dashboard/get_things_to_do', common_data)
          .then(data => {
            this.set_activities(data)
            this.loaded = true;
          })
          .catch(error => {
            console.log(error)
          }).finally(() => {
        this.loaded = true;
      });
    }
  },
}
</script>

<style scoped>

</style>