<template>
  <div class="reviewsHead">
    <div class="left rating-wrap others">
      <p class="reviewTotal  ">{{ reviews.rating }}</p>
      <stars :stars="reviews.rating"></stars>
    </div>
    <div class="right progress-wrap">
      <div class="progress">
        <div v-for="(count, index) in reviews.rating_counts" :key="index" class="progress-inner-wrap">
          <div class="progress-bar">
            <div class="progress-bar__secondary" style="width: 0%;"></div>
            <div class="progress-bar__primary" :style="'width:' + get_bar_width(count)"></div>
          </div>
          <b class="progress-status"> {{ count }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RatingBar",
  props: {
    reviews: {
      type: Object,
    },

  },
  methods: {
    get_bar_width(count){
      let total = this.reviews.total_reviews_count;
      total = (count/total) * 100;
      return total + '%';
    }
  },
}
</script>

<style scoped>

</style>