import {SET_DEAL_DETAIL, SET_TOUR_REVIEWS} from './mutation-types';

export default {
    /**
    *
    * @param { DealState } state
    * @param { Object } data
    */
    [SET_DEAL_DETAIL](state,  data) {
        state.deal_detail = data;
    },

    [SET_TOUR_REVIEWS](state, data) {
        state.tour_reviews = data;
    }
};
