<template>
  <div v-if="loaded" class="pageview active-screen filter-page screen-hdr1 ">
    <div class="thingstodo-page screen-hdr1 active-screen">
      <header class="Header_outer">
        <div class="hdr-title-sec">
          <ul class="hdr-lft-btns">
            <li @click="resetFilters"> <a href="javascript:void(0)" class="text-btn">Reset</a> </li>
          </ul>
          <h2 class="screen-title">Filters</h2>
          <ul class="hdr-right-btns">
            <li @click="setFilters"> <a href="javascript:void(0)" class=" text-btn">Done</a> </li>
          </ul>
        </div>
      </header>
      <div v-if="reload" class="pg-content overflow-auto">
        <div class="flt-multisections">
          <div class="flt-sec-wrap">
            <div class="flt-sec-head">
              <h4> AVAILABILITY </h4>
            </div>
            <div class="flt-sec-det">
              <div class="pln-btns-otr">
                <div class="left pln-switch-btn">
                  <label class="">Special Offers  </label>
                  <div class="switch_btn_outer right">
                    <v-switch
                        class="switch"
                        color="var(--c-secondary)"
                        v-model="special_offers"
                        inset
                    >
                    </v-switch>
                  </div>
                </div>
                <div class="right pln-switch-btn">
                  <label class=" "><i class="ins_confirm_icon"></i> Instant Bookings  </label>
                  <div class="switch_btn_outer right">
                    <v-switch
                        class="switch"
                        color="var(--c-secondary)"
                        v-model="instant_bookings"
                        inset
                    >
                    </v-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="filters.sorting_options" class="flt-sec-wrap">
            <div class="flt-sec-head">
              <h4> {{ filters.sorting_options.title }} </h4>
            </div>
            <div class="flt-sec-det">
              <SingleSelectPills  @selected="pushToFilters" :filter="filters.sorting_options"></SingleSelectPills>
            </div>
          </div>
          <div v-for="(filter, i) in filters.filters" :key="i" class="flt-sec-wrap">
            <div class="flt-sec-head">
              <h4> {{ filter.title }} </h4>
            </div>
            <div class="flt-sec-det">
              <SingleSelectPills :pre_selected="preset" @selected="pushToFilters" v-if="filter.option_selection_type === 'single'" :filter="filter"></SingleSelectPills>
              <MultiSelectPills v-else></MultiSelectPills>
            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</template>

<script>

import MultiSelectPills from "@/components/FilterTypes/MultiSelectPills";
import SingleSelectPills from "@/components/FilterTypes/SingleSelectPills"
import commonMixins from "../../mixins/commonMixins";

export default {
  name: "Filters",
  props: ['preset'],
  components: {
    MultiSelectPills,
    SingleSelectPills
  },
  data() {
    return {
      special_offers: false,
      instant_bookings: false,
      selected_filters: {},
      see_more_active: [],
      reload: true,
      list_dialog: false,
      count: 0,
      loaded: false
    }
  },
  mixins: [commonMixins],
  computed: {
    filters: function (){
      return this.$store.getters['dashboard/get_filters']
    },
  },
  mounted() {
    this.loaded = true;
    if (this.preset && this.preset.api_param_name)
      this.pushToFilters(this.preset)
  },
  methods: {
    setFilters: function(){
      this.count = 0;
      let headers = this.get_form_data();
      for (let filter in this.selected_filters){
        this.count += 1;
        headers.set(filter, this.selected_filters[filter].key)
      }
      if (this.instant_bookings){
        headers.set('is_instant_booking_only', true);
        this.count += 1;
      }
      if (this.special_offers){
        headers.set('is_special_offers_only', true);
        this.count += 1;
      }
      this.$store.dispatch('app/set_loader', true)
      this.$store
          .dispatch('dashboard/get_things_to_do', headers)
          .then(resp => {
              let data = {
                count: this.count,
                data: resp
              }
              this.$emit('close', data);
          }).catch(error => {
            console.log(error)
          })


    },
    pushToFilters: function(option){
      if (option && option.api_param_name) {
        if (this.selected_filters[option.api_param_name] && this.selected_filters[option.api_param_name].key === option.key){
          delete this.selected_filters[option.api_param_name];
        } else {
          this.selected_filters[option.api_param_name] = option;
        }
      }
    },
    resetFilters: function(){
      this.loaded = false;
      this.selected_filters = {};
      this.special_offers = false;
      this.instant_bookings = false;
      this.$emit('reset');
      this.$nextTick(() => {
        this.loaded = true;
      })
    }
  }
}
</script>

<style scoped>

</style>