<template>
  <div>
    <RatingBar :reviews="reviews"></RatingBar>
    <div class="customer-reviews-List">

      <ul class="reviewList">
        <li v-for="(review, index) in listings.reviews" :key="index">
          <div class="head">
            <div class="name-date-wrap">
              <span class="name left">{{ review.ownerName }}</span>
              <span class="date right">{{ review.submissionDate }}</span>
            </div>

            <stars :rating="review.rating"></stars>
          </div>
          <p>
            {{ review.review }}
          </p>
        </li>
      </ul>

      <router-link to="/merchant-review" class="mor-rev-btn gredient2">More Reviews </router-link>
    </div>
  </div>

</template>

<script>
import RatingBar from "@/components/ExperienceDetailSections/RatingBar";
export default {
  name: "Reviews",
  props: {
    reviews: {
      type: Object
    },
    listings: {
      type: Object
    }
  },
  components: {
    RatingBar
  },
  methods: {

  },
}
</script>

<style scoped>

</style>