/**
*
* 
* @param { DashboardState } state
* @return { string }
*/
export function get_home_data(state) {
    return state.home_data;
}

/**
 *
 *
 * @param { DashboardState } state
 * @return { string }
 */
export function get_destinations_data(state) {
    return state.destinations;
}

/**
 * @param { DashboardState } state
 * @return { Object }
 */
export function get_selected_destination(state){
    return state.selected_destination;
}

/**
 *
 * @param state
 */

export function get_activities(state) {
   return state.activities;
}

/**
 *
 * @param state
 * @returns {(function(): (*|undefined))|Object|null}
 */
export function get_attractions(state) {
    return state.attractions;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function get_search_results(state) {
    return state.search_results;
}

/**
 *
 * @param state
 * @returns {[number, number, [], string, string]|(function(): *)|null}
 */
export function get_search_pills(state) {
    return state.pills;
}

export function get_selected_experience(state){
    return state.selected_experience;
}

export function get_filters(state){
    return state.filters;
}

export function get_search_term(state){
    return state.search_term;
}