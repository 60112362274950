import {SET_DEAL_DETAIL, SET_TOUR_REVIEWS} from './mutation-types';
import Repository from "../../../repositories/RepositoryFactory"
const DealRepo  =   Repository.get("deal")

/**
 * Gets deal of a detail
 * @param { function } commit
 * @param payload
 * @param { string } data
 */
export function getDealDetail({ commit },  payload){
    return new Promise((resolve, reject) => {
        DealRepo
            .get_deal_detail(payload)
            .then(resp => resp.data)
            .then(resp =>{
                let data = resp.data;
                commit(SET_DEAL_DETAIL, data );
                resolve(resp)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function emptyDealDetail({commit}) {
    commit(SET_DEAL_DETAIL, {});
}

export async function getTourReviews({commit}, payload) {
    let resp = await DealRepo.get_tour_reviews(payload)
    let data = resp.data.data;
    commit(SET_TOUR_REVIEWS, data);
}