// @vue/component
import SearchComponent from "../../../components/SearchComponent/SearchComponent";
import VueSlickCarousel from 'vue-slick-carousel'
import Autocomplete from "@/components/Autocomplete/Autocomplete";
import MapWithMarkers from "@/components/Maps/MapWithMarkers/MapWithMarkers2";
import POIImages from '@/views/Planner/POIImages/POIImages'
import commonMixins from "../../../mixins/commonMixins";
export default {
    name: 'POIBuild',

    components: {
      SearchComponent,
      VueSlickCarousel,
      Autocomplete,
      MapWithMarkers,
      POIImages
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {
          settings: {
              "dots": false,
              "autoplay": false,
              "dotsClass": "slick-dots slider-dots",
              "edgeFriction": 0.0,
              "infinite": false,
              "speed": 500,
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "arrow":false
          },
          "switch1": false,
          items: [],
          selected_items: [],
          plan_items: [],
          place: null,
          dialog: false,
          current_item: null
        }
    },

    computed: {
        plan_draft: function(){
           return this.$store.getters['planner/plan_draft']
        },
        pages: function() {
            return JSON.parse(JSON.stringify(this.$store.getters['backbutton/get_pages']));
        }
    },
    methods: {
        goBack() {
            this.$store.dispatch('backbutton/pluck_last_page').then(() => {
                let last_page = this.pages[this.pages.length - 1];
                this.$router.push(last_page.path);
            });
        },
        show_details: function (item){
              this.current_item = item
            this.dialog = true;
        },
        toggle_planning_item: function(item){
            let result = this.selected_items.find(i => i.place_id === item.place_id)
            if (result)
                this.selected_items.splice(this.selected_items.indexOf(result), 1)
            else
                this.selected_items.push(item)
        },
        setPlace(place){
            if (!place)
                return
            this.$nextTick(() => {
                this.items.unshift(place);
            })
            this.place = place
        },
        getUrl(item){
            return item.photos[0].getUrl({width: 640, height: 480})
        },
        save_items_to_store: function (){
            this.plan_items = [];
            for (let item in this.selected_items){
                let obj = this.selected_items[item]
                let data = {
                    title: this.selected_items[item].name,
                    data_source: 'google_place',
                    item_type: 'google_place',
                    lat: obj.geometry.location.lat(),
                    lng: obj.geometry.location.lng(),
                    image_url: obj.photos[0].getUrl(),
                    image: obj.photos[0].getUrl(),
                    image_non_retina: obj.photos[0].getUrl(),
                    image_retina: obj.photos[0].getUrl(),
                    logo: obj.icon,
                    logo_non_retina: obj.icon,
                    logo_retina: obj.icon,
                    display_name: obj.name,
                    placeId: obj.place_id,
                    id: obj.place_id,
                }
                this.plan_items.push({...data})
            }
            for (let item in this.plan_draft) {
                this.plan_items.push(this.plan_draft[item])
            }

            this.$store.dispatch('planner/add_to_plan_draft', this.plan_items)
            this.$router.push('create-plan/create');
        }
    }
}
