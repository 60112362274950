import { SET_GUIDES } from "./mutation-types";
import Repository from "@/repositories/RepositoryFactory"
const guidebookRepo  =   Repository.get("guidebook")
export function get_all_guides({commit}, payload){
    return  new Promise((resolve, reject) => {
        guidebookRepo.
        get_ll_guidebooks(payload)
            .then(resp => resp.data)
            .then(resp => {
                let data = resp.data
                commit(SET_GUIDES, data)
                resolve(data)
            })
            .catch(err => reject(err))
    })

}