<template>
    <div class="scr-btm-Buttons twofilterlist ">
      <div class="buttons gredient2">
        <slot></slot>
      </div>
    </div>
</template>

<script>
    export default {
      props: {
        },
    };
</script>
