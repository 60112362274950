export function get_active_filters_count(state) {
    return state.active_filters_count;
}

export function get_dialog_status(state) {
    return state.is_dialog_active;
}

export function get_active_filters(state){
    return state.filters;
}
export function get_preset_filters(state) {
    return state.preset_filters;
}