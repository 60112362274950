import axios from 'axios';

const baseDomain    =   process.env.VUE_APP_SITE_URL;

const baseURL   =   `${baseDomain}/api2/web`;
export default axios.create({
    baseURL,
    headers: {
    }
})
