<template>
  <ul v-if="loaded" class="flt-sec-listing">
    <li :class="j< 4 || is_option_selected(option)  ? 'displayed' : 'hidden'" v-for="(option, j) in filter.options"  @click="push_to_filters(option)" :key="j">
      <a :class="get_class(option) ? 'active' : ''" href="#">{{ option.name }}</a>
    </li>
    <li v-if="filter.options.length > 4" @click="list_dialog = true"><button class="btn flt-more-icon" type="button" name="button"></button></li>
    <v-dialog v-model="list_dialog" fullscreen>
      <FilterListing @close="updatePills" :selected_option="selected_pill" :options="filter" :is_single="true"></FilterListing>
    </v-dialog>
  </ul>
</template>

<script>
import Listing from "./Listing"
export default {
  name: "SingleSelectPills",
  props:['filter', 'pre_selected'],
  components: {
    'FilterListing' : Listing
  },
  data() {
    return {
      selected_pill: {},
      list_dialog: false,
      loaded: true
    }
  },

  computed: {
    active_filter: function (){
      return this.$store.getters['filters/get_active_filters']
    },

  },
  created() {
    if (this.pre_selected && this.pre_selected.api_param_name === this.filter.api_param_name){
      this.selected_pill = this.pre_selected;
    }
  },
  methods: {
    is_option_selected: function(option){
      if (this.selected_pill)
      return option.key === this.selected_pill.key;
    },
    /**
     * Changes a selected pill
     * If a pill is already selected unselects it
     * nextTick reloads this component so right class is rendered
     * @param option
     */
    push_to_filters(option) {
      if (option === this.selected_pill){
        this.selected_pill = {};
        this.$emit('selected', option)
      } else {
        this.selected_pill = option;
        this.$emit('selected', option)
      }
    },
    updatePills: function(option){
      this.loaded = false;
      this.selected_pill = option;
      this.list_dialog = false;
      this.$nextTick(() => {
        this.loaded = true;
      })
      this.$emit('selected', option)
    },
    get_class: function(option){
      if (this.selected_pill)
        return option.key === this.selected_pill.key;
    },
  },
}
</script>

<style scoped>
  .displayed {
    display: unset !important;
  }
  .hidden {
    display: none !important;
  }
  .active {
    font-weight: 500;
    background: linear-gradient(to right, rgba(0, 207, 255, 1) 1%, rgba(77, 232, 232, 1) 99%);
    background: -webkit-linear-gradient(to right, rgba(0, 207, 255, 1) 1%, rgba(77, 232, 232, 1) 99%);
    background: -moz-linear-gradient(to right, rgba(0, 207, 255, 1) 1%, rgba(77, 232, 232, 1) 99%);
  }
</style>