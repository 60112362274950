// @vue/component
import ActivityCard from "@/components/ActivityCard/ActivityCard";
import EmptyResultComponent from "@/components/NoOfferComponent/EmptyResultComponent";
import iconWishlist from '@/assets/images/ic-wishlistEmpty.png'
import commonMixins from "@/mixins/commonMixins";
export default {
    name: 'WishlistItems',

    components: {
        ActivityCard,
        EmptyResultComponent
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {
            plan_items: [],
            selected_items: [],
            iconWishlist
        }
    },

    computed: {
        wishlistItems () {
            return this.$store.getters['wishlist/wishlist_items']
        },
        plan_draft: function(){
            return this.$store.getters['planner/plan_draft']
        }
    },
    watch: {},
    created () {
        this.plan_items = JSON.parse(JSON.stringify(this.wishlistItems))
        this.selected_items = this.plan_draft;
    },
    methods: {
        move_to_experience_detail(experience) {
            this.$store.dispatch('dashboard/set_selected_experience', experience);
            this.$router.push(`/experience-detail/${experience.item_code}`);
        },
        addToPlan: function (data) {
            this.selected_items.push(data)
        },
        save_draft: function (){
            this.$store.dispatch('planner/add_to_plan_draft', this.selected_items)
            this.$router.push('/planner/create-plan/create')
        }

    }
}
