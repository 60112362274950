import { TOGGLE_WISHLIST_ITEM } from "@/store/modules/wishlist/mutation-types";


export default {
    [TOGGLE_WISHLIST_ITEM] (state, wishlist_item){
        let item = state.wishlist_items.find(item => item.item_code === wishlist_item.item_code)
        if (item)
            state.wishlist_items.splice(state.wishlist_items.indexOf(item), 1)
        else
        state.wishlist_items.push(wishlist_item);
    }
}