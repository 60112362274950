// @vue/component
import VueSlickCarousel from 'vue-slick-carousel'
import * as $ from 'jquery';
import Placeholder from '@/assets/images/placeholder.png'
import Reviews from "@/components/ExperienceDetailSections/Reviews";
import Amenities from "@/components/ExperienceDetailSections/Amenities";
import BookWithConfidence from "@/components/ExperienceDetailSections/BookWithConfidence";
import Schedule from "@/components/ExperienceDetailSections/Schedule"
import ImportantInfo from "@/components/ExperienceDetailSections/ImportantInfo";
import SliderLarge from "@/components/Sliders/SliderLarge";
import MerchantReviews from "@/components/ExperienceDetailSections/MerchantReviews";
import MapWithMarkers from "@/components/Maps/MapWithMarkers/MapWithMarkers";
import ExpandablePanel from "@/components/ExpandablePanels/ExpandablePanel";
import commonMixins from "../../mixins/commonMixins";
export default {
    name: 'ExperienceDetail',

    components: {
        VueSlickCarousel,
        Reviews,
        Amenities,
        BookWithConfidence,
        Schedule,
        ImportantInfo,
        SliderLarge,
        MerchantReviews,
        MapWithMarkers,
        ExpandablePanel
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {
            Placeholder,
            read_more: false,
            active_section: '',
            settings: {
                "dots": true,
                "autoplay": false,
                "dotsClass": "slick-dots slider-dots",
                "edgeFriction": 0.1,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "arrow":false
            },
            data_source: '',
            item_id: '',
            title: '',
            direction_dialog: false,
            loader: false
        }
    },
    computed: {

        item_type: function(){
            if(!this.$route.params.id)
                return null;
            return this.$route.params.id.includes('attraction') ? 'viator_attraction' : 'viator_tour';
        },
        selected_deal(){
            return this.$store.getters["dashboard/get_selected_experience"]
        },
        deal_details(){
            return this.$store.getters["deal/deal_detail"]
        },
        details(){
            return this.get_section('detail')
        },
        important_information(){
            return this.get_section('importantinfosection')
        },
        reviews(){
            return this.get_section('reviews')
        },
        review_listing(){
            return this.get_section('review_listing')
        },
        tour_schedule(){
            return this.get_section('tour_schedule')
        },
        book_with_confidence(){
            return this.get_section('book_with_confidence')
        },
        nearby(){
            return this.get_section('nearby')
        },
        highlights(){
            return this.get_section("tour_highlights")
        },
        selected_destination(){
            return this.$store.getters['dashboard/get_selected_destination']
        },
        availability_url(){
            return process.env.VUE_APP_PURCHASE_URL;
        },
        user(){
            return this.$store.getters['auth/getUser']
        },
        item_code: function(){
            return this.$route.params.id
        },
        overview: function(){
            return this.get_section('toursoverview');
        },
        is_attraction: function (){
            if (!this.$route.params.id)
                return null;
            return this.$route.params.id.includes('attraction');
        }
    },
    watch: {
        item_code: 'dispatch_deal_detail'
    },
    mounted () {
        this.dispatch_deal_detail()
        $("#content").scroll(  () => {
            let scroll = $("div#content").scrollTop();
            if(scroll > 5){
                $("#hdr-top").removeClass('Hdr-bg-none');
                this.title = this.deal_details.title ? this.deal_details.title : this.deal_details.display_name;
            }else{
                this.title = ''
                $("#hdr-top").addClass('Hdr-bg-none');
            }
        });
    },
    methods: {
        cuckoo_url: function(){
            return this.easy_encrypt(window.location.href)
        },
        check_availability: function(){
            this.$store.dispatch('app/set_loader', true)
            this.$refs.check_availability.submit();
        },
        toggle_active_section(section){
            if (!this.active_section)
                this.active_section = section;
            else
                this.active_section = '';
        },
        get_section(identifier){
            let sections = this.deal_details.details;
            if (!sections)
                return
            for (let i = 0; i < sections.length; i++) {
                if (sections[i].section_identifier === identifier)
                    return sections[i];
            }
        },
        dispatch_deal_detail: function (){
            let common_data = this.get_form_data()
            common_data.set('data_source', this.selected_deal.data_source);
            common_data.set('item_code', this.item_code);
            common_data.set('item_type', this.item_type);
            if(this.is_attraction){
                let id = this.$route.params.id.split('_');
                id = id[id.length -1]
                common_data.set('item_id', id);
            }
            this.$store.dispatch('deal/getDealDetail', common_data)

            let image_holder = $('.img-holder')
            if (image_holder && image_holder.length > 0)
                image_holder[0].scrollIntoView()
        }
    }
}
