import {
    ADD_TO_PLAN_DRAFT,
    PUSH_TO_PLAN_DRAFT,
    REMOVE_FROM_PLAN_DRAFT,
    SET_RECOMMENDED_PLANS,
    RESET_PLAN_DRAFT,
    SET_SELECTED_PLAN,
    SET_PLAN_DETAILS
} from "./mutation-types";


export default {
    [SET_RECOMMENDED_PLANS] (state, recommended_plans){
        state.recommended_plans = recommended_plans;
    },
    [ADD_TO_PLAN_DRAFT] (state, plans){
        state.plan_draft = [...plans];
    },
    [PUSH_TO_PLAN_DRAFT] (state, plan){
        let item = state.plan_draft.find(item => item.item_code === plan.item_code)
        if (item)
            state.plan_draft.splice(state.plan_draft.indexOf(item), 1)
        else
            state.plan_draft.push(plan)
    },

    [REMOVE_FROM_PLAN_DRAFT] (state, plan){
        state.plan_draft.splice(state.plan_draft.indexOf(plan), 1)
    },
    [SET_SELECTED_PLAN] (state, plan){
        state.selected_plan = plan
    },
    [SET_PLAN_DETAILS] (state, plan){
      state.plan_details = plan
    },
    [RESET_PLAN_DRAFT] (state){
        state.plan_draft.splice(0, state.plan_draft.length)
    }
}