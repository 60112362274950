import Vue from 'vue'
import App from './App.vue'


import VueMeta from "vue-meta";
Vue.use(VueMeta);

let env = process.env.NODE_ENV

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if(env === 'development') {
  Sentry.init({
    Vue,
    dsn: "https://e56b4d866d4f4723a0f4a3be459fb0dd@o497472.ingest.sentry.io/5587435",
    autoSessionTracking: true,
    logErrors: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracingOptions: {
      trackComponents: true
    },
//
//   We recommend adjusting this value in production, or using tracesSampler
//   for finer control
    tracesSampleRate: 1.0,
  });

}

import router from './router/router'

import {setup} from './repositories/clients/interceptors';
setup();

import store from './store/index';
import './assets/css/global.min.css';
import './assets/css/custom.min.css';

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'



//Plugins
import GlobalComponents from "@/globalComponents";

Vue.use(GlobalComponents)

//gmaps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyD1kC-5pN5sLxpLCvqQ9PK-PR_ncLBOlYg',
    key: 'AIzaSyDHqd6SaFH3UkFYPHnxUFsB9Q1h7-MxGD4',
    libraries : 'places', // This is required if you use the Autocomplete plugin
  },

})

import vuetify from './plugins/vuetify';


Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
