const Rijndael = require('rijndael-js');
var iv = '9994067432761232'
var salt_key = '30e495dae0a28fc00e9dab9785b32429'
export default {
    methods: {
        move_to_detail(experience){
            this.$store.dispatch('deal/emptyDealDetail')
            this.$store.dispatch('dashboard/set_selected_experience', experience);
            this.$router.push(`/experience-detail/${experience.item_code}`).catch(() => {
                location.reload();
            });
        },
        get_wishlist_class(item){
            let items = this.$store.getters['wishlist/wishlist_items'];
            if (!items || !item)
                return
            let result = items.find(i => i.item_code === item.item_code)
            return !result;
        },
        get_planning_class(item){
            let items = this.$store.getters['planner/plan_draft']
            let result = items.find(i => i.item_code === item.item_code)
            return !result;
        },
        get_planning_class_from_map_items(item, items){
            let result = items.find(i => i.place_id === item.place_id)
            return !result;
        },
        get_form_data(){
            let commonData = JSON.parse(JSON.stringify(this.$store.getters['app/getCommonData']));
            let form_data = new FormData();
            for ( let key in commonData ) {
                form_data.append(key, commonData[key]);
            }
            return form_data;
        },
        get_form_data_with_auth(){
            let headers = this.get_form_data()
            let auth_data = this.$store.getters['auth/auth_data']
            for ( let key in auth_data ) {
                headers.set(key, auth_data[key]);
            }
            return headers;
        },
        easy_encrypt(value){
            if (!value)
                return value;
            const cipher = new Rijndael(salt_key, 'cbc')
            const ciphertext = Buffer.from(cipher.encrypt(value, 128, iv))
            return ciphertext.toString('base64')
        },
        log(msg, type ) {
            // Define different types of styles
            let baseStyles = [
                "color: #fff",
                "background-color: #444",
                "padding: 2px 4px",
                "border-radius: 2px"
            ].join(';');
            let ajaxStyles = [
                "color: #eee",
                "background-color: red"
            ].join(';');
            let domStyles = [
                "background-color: gray"
            ].join(';');
            let successStyles = [
                "background-color: green"
            ].join(';');
            // Set style based on input type
            let style = baseStyles + ';';
            switch(type) {
                case "ajax": style += ajaxStyles; break;
                case "dom": style += domStyles; break;
                case "success": style += successStyles; break;
                default: break;
            }
            if (typeof msg === "object")
                console.log(msg);
            else
            console.log(`%c${msg}`, style);
        }


    }
}