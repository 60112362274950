// @vue/component
import ExperienceListingMapView from '../ExperienceListingMapView/ExperienceListingMapView';
export default {
    name: 'ExperienceListing',

    components: {
        ExperienceListingMapView,
    },

    props: {},
    data () {
        return {
            active_tab: null,
            loaded: false,
            scrolledToBottom: false
        }
    },
    computed: {
        activeFilters: function(){
            return this.$store.getters['filters/get_active_filters']
        },
        is_planner: function(){
            return this.$route.name === 'planner-activities' || this.$route.name === 'planner-attractions';
        },

    },

    watch: {
        $route: function(){
            this.$store.dispatch('filters/reset_filters')
            this.offset = 0;
        },
    },
    beforeDestroy() {
        this.$store.dispatch('filters/reset_preset_filters')
        this.$store.dispatch('filters/reset_filters')
    },
    beforeMount() {
        this.$store.dispatch('filters/reset_filters')
        this.setTab()
    },
    methods: {
        setTab: function(){
            let tab = this.$route.params.tab
            if (tab === 'activities'){
                this.category_id  = 3;
                this.deal_type = 'tours';
            } else {
                this.category_id = 9;
                this.deal_type = 'attractions';
            }
        },

    }
}
