<template>
  <div class=" pageview screen-hdr1 Exp-list-map-page active-screen">
    <header class="Header_outer">
      <div class="hdr-title-sec">
        <ul class="hdr-lft-btns">
          <li class="">
            <v-btn href="#" @click="$emit('cancel')" class="back-arrow"> </v-btn>
          </li>
        </ul>
        <h2 class="screen-title">{{ details.title }}</h2>

      </div>
    </header>
    <div class="pg-content thingstodo-det">
      <div class=" maplist-cmn-cls full_size_div d-block">
        <div class="map-sec">
          <MapWithMarkers :center.sync="center" ref="mapRef" marker_type="dynamic" :outlets="details.outlets" hw="height: 800px; width:100%" :zoom="12"></MapWithMarkers>
        </div>
      </div>
      <!-- -->
      <div class="scr-btm-overlay">


        <!-- -->
        <div v-if="details" class="planCarousel">

          <VueSlickCarousel @afterChange="updateMapCenter" v-bind="settings" >
            <div class="planTab plan_attr_list" v-for="(tile,i) in details.outlets" :key="i"  v-ripple>
              <div class="img_holder">
                <v-image ratio="2" class="img_responsive" :src="tile.image_url"></v-image>
              </div>
              <div class="text_holder">
                <h2 class="">{{ tile.name}}</h2>

                <div class=" get_detail_dis_outer ">
                  <div class="get_detail_dis">
                  </div>

                </div>
              </div>
            </div>
          </VueSlickCarousel>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapWithMarkers from "@/components/Maps/MapWithMarkers/MapWithMarkers2";
import VueSlickCarousel from 'vue-slick-carousel';
import commonMixins from "../../mixins/commonMixins";

export default {
  name: "PlannerItemListingMapView",
  components: {MapWithMarkers, VueSlickCarousel},
  props: ['details'],
  mixins: [commonMixins],
  methods: {
    updateMapCenter: function (index){
      if (this.details && this.details.outlets) {
        let outlet = this.details.outlets[index];
        this.center = {lat: outlet.lat, lng: outlet.lng}
      }

    }
  },
  data() {
    return {
      center: null,
      settings: {
        "dots": false,
        "autoplay": false,
        "dotsClass": "slick-dots slider-dots",
        "edgeFriction": 0.0,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrow":false
      },
    }
  },
}
</script>

<style scoped>

</style>