import AuthRepository from "./AuthRepository";
import HomeRepository from './HomeRepository'
import CategoriesRepository from "./CategoriesRepository";
import DealRepository from './DealRepository';
import DayPlannerRepository from "@/repositories/DayPlannerRepository";
import GuideBookRepository from "@/repositories/GuideBookRepository";
const repositories = {
    'auth'  :   AuthRepository,
    'home'  :   HomeRepository,
    'categories'    :   CategoriesRepository,
    'deal'  :   DealRepository,
    'planner' : DayPlannerRepository,
    'guidebook' : GuideBookRepository
}

export default {
    get: name => repositories[name]
}