import Client from './clients/axios-client'

const resource = '/home-v'

export default {
    get_home_data(payload) {
        return Client.post(`${resource}/get-home-data/cuckoo`, payload);
    },
    get_destinations(payload){
        return Client.post(`${resource}/get-destinations`, payload)
    },
    get_things_to_do_list(payload){
        return Client.post(`${resource}/get-things-to-do-list-data`, payload);
    },
    get_home_outlets(payload){
        return Client.post(`${resource}/get-home-outlets`, payload)
    },
    get_filters(payload){
        return Client.post(`${resource}/get-filters`, payload)
    }
}