import Client from './clients/axios-client'
import LocalClient from './clients/axios-local-client'
import BookingClient from './clients/axios-booking-client'
const resource  =   '/user-v';

export default {
    auth_token(){
        return LocalClient.get('/home-v/make-auth-header-key');
    },
    get_user_details(token){
        let session_token = token['__t'];
        let company = token['company'] ? token['company'] : 'visax';
        let postData = new FormData();
        postData.set('company', company);
        postData.set('session_token', session_token);
        return LocalClient.post(`/default/user-profile`,postData);
    },
    get_ticket_data(payload){
        return Client.post(`${resource}/get-ticket-data`, payload)
    },
    cancel_ticket(payload){
        return BookingClient.get(`/booking/cancel?ref=${payload}`);
    },
    get_profile_data_p(payload){
        return Client.post(`${resource}/get-profile-data-p`, payload)
    }

}
