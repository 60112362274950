import {SET_DATA, SET_CONNECTIVITY_STATE, SET_DESTINATION_DATA,SET_TOKEN, SET_MENU_STATE, SET_PREVIOUS_PAGE, SET_SEARCH_STATE, SET_LOADER_STATE} from './mutation-types';

export default {
    /**
     *
     * @param { AppState } state
     * @param data
     */
    [SET_DATA](state, data) {
        state.common_data =  data;
    },
    [SET_TOKEN](state, data){
        state.__t = data;
    },
    /**
     *
     * @param state
     * @param { Object } data
     */
    [SET_SEARCH_STATE] (state, data) {
        state.is_searching = data;
    },
    /**
     *
     * @param state
     * @param data
     */
    [SET_DESTINATION_DATA] (state, data) {
        state.common_data['__destination_id_viator'] = data['destination_id_viator']
        state.common_data['__destination_id_ego'] = data['destination_id_ego']
        state.common_data['destination_iso_code'] = data['iso_code']
    },
    [SET_PREVIOUS_PAGE] (state, page) {
        state.previous_page = page;
    },
    /**
     *
     * @param state
     * @param menu_state
     */
    [SET_MENU_STATE] (state, menu_state = !state.is_menu_visible) {
        state.is_menu_visible = menu_state;
    },
    /**
     *
     * @param state
     * @param loader
     */
    [SET_LOADER_STATE] (state, loader){
        state.loader = loader
    },

    [SET_CONNECTIVITY_STATE] (state, connectivity){
        state.connectivity = connectivity;
    }
};
