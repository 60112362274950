<template>
  <div class="pageview active-screen no-internet-pg Hdr-bg-none">
    <header class="Header_outer">
    </header>
    <div class="pg-content no-offer-view no-internet full_size_div ">
      <div class="no-offer-view-inner">
        <span class="offer-icon"> <img src="../../assets/images/ic-nointernet.png" alt="#" /> </span>
        <h3>Connect to the Internet</h3>
        <p>You’re offline. Check your connection.</p>
        <button @click="reload" class="gredient2">Tap to retry</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoInternet",
  mounted() {
    navigator.connection.onchange = this.connection_change
  },
  methods: {
    reload(){
      location.reload()
    },
    connection_change: function(status){
      console.log(status)
      this.$store.dispatch('auth/refresh_session_token').then(() => {
        this.$store.dispatch('app/set_connectivity', true)

      }).catch((error) => {
        console.log('error: ',error.response.status)
      });
    },
  },
}
</script>
<style scoped>

</style>