// @vue/component
import SearchFixed from '../../components/SearchFixed/SearchFixed';
import Pills from "../../components/Pills/Pills";
import NoOfferComponent from "../../components/NoOfferComponent/NoOfferComponent"
import ActivityCard from "../../components/ActivityCard/ActivityCard";
import _ from "lodash"
import commonMixins from "../../mixins/commonMixins";
export default {
    name: 'AddBySearch',


    components: {
        SearchFixed,
        Pills,
        NoOfferComponent,
        ActivityCard
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {
            deal_type: '',
            category_id: '',
            page_size: 20,
            sort_by: 'sort_by_near_by',
            offset: 0,
            term: '',
        }
    },
    computed: {
        things_to_do(){
            return this.$store.getters['dashboard/get_search_results'];
        },
        pills: function(){
            return this.$store.getters['dashboard/get_search_pills'];
        },
        pages: function() {
            return JSON.parse(JSON.stringify(this.$store.getters['backbutton/get_pages']));
        }


    },
    watch: {},
    mounted () {
        this.$store.dispatch('dashboard/empty_search_results');
        this.category_id = this.$route.params.category_id;
        this.deal_type = this.$route.params.deal_type;
        this.term = this.$route.params.query;
        this.$nextTick(() => {
            this.$refs.search.focus();
        })

    },
    methods: {
        cancelSearch () {
            this.$store.dispatch('backbutton/pluck_last_page').then(() => {
                let last_page = this.pages[this.pages.length - 1];
                this.$router.push(last_page.path);
            });
        },

        search: function (){
            let common_data = this.get_form_data()
            common_data.set('query', this.term);
            common_data.set('deal_type', this.deal_type);
            common_data.set('category_id', this.category_id);
            this
                .$store
                .dispatch('dashboard/get_home_outlets', common_data)
                .then(() => {
                })
                .catch(error => {
                    console.log(error)
                });
        },
        updateSearchCriteria: function (pill){
            let params = pill.api_params;
            this.category_id = params.category_id;
            this.deal_type = params.deal_type;
            this.search();
        },
        handleSearch: _.debounce(function (term) {
            this.term = term;
            if (!this.$route.params.category_id) {
                this.category_id = '';
                this.deal_type = '';
            }
            if (!term){
                return
            }
            this.search()
        }, 500)
    }
}
