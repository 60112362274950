<template>
  <li @click="goBack" class="">
    <a class="back-arrow"> </a>
  </li>
</template>

<script>
export default {
  name: "BackButton",
  computed: {
    to: function(){
      let current_route = window.location.pathname;
      let previous_route = this.$routerHistory.previous().path
      if (this.$routerHistory.hasPrevious() && current_route !== previous_route)
        return { path: previous_route  }
      return '/home';
    },
    pages: function() {
      return JSON.parse(JSON.stringify(this.$store.getters['backbutton/get_pages']));
    }
  },
  methods: {
    goBack() {
      this.$store.dispatch('backbutton/pluck_last_page').then(() => {
        let last_page = this.pages[this.pages.length - 1];
        this.$router.push(last_page.path).catch(() => { location.reload() });
      });
    }
  },

}
</script>

<style scoped>

</style>