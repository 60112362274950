// @vue/component
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import VueSlickCarousel from 'vue-slick-carousel'
import MarkerActiveIcon from '../../assets/images/pin_active_10.png';
import MarkerIcon from '../../assets/images/pin_11.png';
import commonMixins from "../../mixins/commonMixins";
export default {
    name: 'CreateDayPlanMapView',
    components: {
      SearchComponent,
      VueSlickCarousel
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {
            MarkerActiveIcon,
            MarkerIcon,
          settings: {
              "dots": false,
              "autoplay": false,
              "dotsClass": "slick-dots slider-dots",
              "edgeFriction": 0.0,
              "infinite": false,
              "speed": 500,
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "arrow":false
          },
          "switch1": false,


        }
    },

    computed: {
        wishlist_draft: function () {
            return this.$store.getters['wishlist/wishlist_items']
        }
    },

    watch: {},

    created () {},

    methods: {
        updateMapCenter: function (index){
            let thing_to_do = this.wishlist_draft[index];
            this.$nextTick(() => {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.setZoom(10)
                    map.panTo({lat: thing_to_do.latitude, lng: thing_to_do.longitude})
                })
            })
        },
        getProperty(item) {
            if (Object.hasOwnProperty.call(item,'latitude') && Object.hasOwnProperty.call(item,'longitude')) {
                return { lat: item.latitude, lng: item.longitude }
            } else if(Object.hasOwnProperty.call(item,'lat') && Object.hasOwnProperty.call(item,'lng')) {
                return { lat: item.lat, lng: item.lng }
            }
        }
    }
}
