<template>
  <div>
    <div  class="listingBlock" v-for="(destination, index) in destinations" v-bind:key="index">

                <span class="alpha">
                    <div class="table">
                        <div class="table-cell" :id="destination.title">{{ destination.title }}</div>

                    </div>
                </span>
      <ul  class="cityList">
        <li @click="showDialog(country)" v-ripple v-for="(country, i) in destination.destinations" :key="i">
          <span class="title ">{{ country.name }}</span>
          <span class="text-right "></span>
        </li>
      </ul>
    </div>
    <div class="text-center">
      <v-app id="inspire">
        <v-layout row justify-center>
          <v-dialog v-model="dialog" scrollable max-width="90%">
            <v-card v-if="selected_country">


              <v-card-title >

                {{ selected_country.name }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-list>
                  <v-list-item-group>
                    <v-list-item @click="setSelectedDestination(city)" v-for="(city, i ) in selected_country.cities" :key="i">
                      <v-list-item-content>
                        <v-list-item-title v-text="city.name"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-app>
    </div>
  </div>
</template>

<script>
    export default {
        props: {
          destinations: Array
        },
      data() {
        return {
          dialog: false,
          selected_country: null,
        }
      },
      methods: {
        showDialog(country) {
          this.dialog = true
          this.selected_country = country;
        },
        setSelectedDestination(city) {
          this.$store.dispatch('dashboard/set_selected_destination', city);
          this.$router.push('/home');
        }
      },
    };
</script>
