import { SET_USER, SET_TOKEN, SET_TICKETS, SET_AUTH_DATA } from './mutation-types';

export default {
    /**
    *
    * @param { AuthState } state
    * @param { string } data
    */
    [SET_USER](state,  user) {
        state.user = user;
    },
    [SET_TOKEN](state, token) {
        state.token = token;
    },
    [SET_TICKETS] (state, tickets) {
        state.tickets = tickets;
    },
    [SET_AUTH_DATA] (state, authData) {
        state.auth_data = authData;
    }

};
