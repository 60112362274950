// @vue/component

import PlanListItem from "@/components/PlanListItem/PlanListItem";
import draggable from "vuedraggable";
import MapWithMarkers from "@/components/Maps/MapWithMarkers/MapWithMarkers";
import moment from "moment";
import commonMixins from "../../../mixins/commonMixins";
export default {
    name: 'CreatePlan',

    components: {
        PlanListItem,
        draggable,
        MapWithMarkers
    },

    mixins: [commonMixins],
    props: {},
    data () {
        return {
            is_public: false,
            plan_name: '',
            plan_date: null,
            alert: false,
            msg: '',
            modal: false
        }
    },

    computed: {
        plan_draft: function () {
            return this.$store.getters['planner/plan_draft']
        },
        auth_data : function(){
            return this.$store.getters['auth/auth_data']
        },
        plan_details: function (){
            let details = this.$store.getters['planner/plan_details'];
            if (details && details.plans)
                return details.plans[0]
            return null
        },
        user: function (){
            return this.$store.getters['auth/getUser']
        },
        plan_info: function (){
            let plans = this.$store.getters['planner/recommended_plans']
            if (plans)
                return plans.info
        },

        draggablePlans: {
            get(){
                return this.$store.getters['planner/plan_draft']
            },
            set(val){
                return this.$store.dispatch('planner/add_to_plan_draft', val)
            },
        },
        mode: function(){
            return this.$route.params.mode ? this.$route.params.mode : 'create';
        }
    },

    watch: {},

    created () {
        if (this.mode === 'edit' && this.plan_details){
            this.plan_name = this.plan_details.title;
            this.plan_date = moment(this.plan_details.plan_date).format('YYYY-MM-DD');
            this.is_public = this.plan_details.is_public;
        }
    },

    methods: {

        verify_inputs: function (){
            this.plan_name = this.plan_name.trim();
            if (!this.plan_name || this.plan_name.length <= 0){
                this.alert = true;
                this.msg = 'Please enter a valid plan name'
                return true;
            }
            if (!this.plan_date){
                this.alert = true
                this.msg = 'Please enter a valid date'
                return true;
            }
            if (this.plan_draft.length < this.plan_info.dayplan_minimum_items_limit){
                this.alert = true;
                this.msg = this.plan_info.dayplan_limit_minimum_message ? this.plan_info.dayplan_limit_minimum_message : 'Please select at least 2 experiences';
                return true;
            } if (this.plan_draft.length > this.plan_info.dayplan_max_items_limit){
                this.alert = true;
                this.msg = this.plan_info.dayplan_limit_exceed_message;
                return true;
            }
            return false
        },

        save_plan: function () {
            if (this.verify_inputs())
            return;
            let headers = this.get_form_data_with_planner_params()
            for (let plan in this.plan_draft){
                let data = this.plan_draft[plan]
                for (let property in data){
                    headers.set(`plan_items[${plan}][${property}]`, data[property])
                }
            }
            this.$store
                .dispatch('planner/create_day_plan', headers)
                .then(() => {
                    this.$router.push('/planner')
                }).catch(error => {
                console.error(error)
            })
        },
        get_form_data_with_planner_params: function(){
            let headers = this.get_form_data_with_auth()
            let is_public = this.is_public ? 1 : 0
            headers.set('is_public', is_public)
            headers.set('plan_title', this.plan_name)
            headers.set('plane_date', this.plan_date)
            return headers;
        },
        edit_plan: function () {
            this.plan_name = this.plan_name.trim();
            if (!this.plan_name || this.plan_name.length <= 0){
                this.alert = true;
                this.msg = 'Please enter a valid plan name'
                return true;
            }
            let headers  = this.get_form_data_with_planner_params()
            headers.set('plan_id', this.plan_details.id)
            for (let plan in this.plan_details.outlets){
                let data = this.plan_details.outlets[plan]
                for (let property in data){
                    headers.set(`plan_items[${plan}][${property}]`, data[property])
                }
            }
            this.$store
                .dispatch('planner/update_day_plan', headers)
                .then(() => {
                    this.$router.push('/planner')
                }).catch(error => {
                console.error(error)
            })
        },


    }
}
