// @vue/component
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import VueSlickCarousel from 'vue-slick-carousel';
import BottomNavigationBar from "../../components/BottomNavigationBar/BottomNavigationBar";
import GradientButton from "../../components/GradientButton";
import ListingIcon from '../../assets/images/listing-icon.png';
import MarkerActiveIcon from '../../assets/images/pin_active_10.png';
import MarkerIcon from '../../assets/images/pin_11.png';
import commonMixins from "../../mixins/commonMixins";
export default {
    name: 'ExperienceListingMapView',

    components: {
      SearchComponent,
      VueSlickCarousel,
      BottomNavigationBar,
      GradientButton
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {
           ListingIcon,
            MarkerIcon,
            MarkerActiveIcon,
          settings: {
              "dots": false,
              "autoplay": false,
              "dotsClass": "slick-dots slider-dots",
              "edgeFriction": 0.0,
              "infinite": false,
              "speed": 500,
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "arrow":false
          },
          "switch1": false,


        }
    },

    computed: {
        things_to_do() {
            return this.$store.getters['dashboard/get_attractions'].things_to_do;
        },
        type(){
            return this.$route.params.type;
        },
        pages: function() {
            return JSON.parse(JSON.stringify(this.$store.getters['backbutton/get_pages']));
        }
    },

    watch: {},

    created () {},

    methods: {
        goBack() {
            this.$store.dispatch('backbutton/pluck_last_page').then(() => {
                let last_page = this.pages[this.pages.length - 1];
                this.$router.push(last_page.path);
            });
        },
        updateMapCenter: function (index){
            let thing_to_do = this.things_to_do[index];
            this.$nextTick(() => {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.setZoom(10)
                    map.panTo({lat: thing_to_do.latitude, lng: thing_to_do.longitude})
                })
            })
        }
    }
}
