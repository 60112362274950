<template>
  <div class="rating">
    <span class="rating_star" :style="'width:'+stars *20 +'%'"></span>
  </div>
</template>

<script>
export default {
  name: "Stars",
  props: {
    stars: null
  },
}
</script>

<style scoped>

</style>