/** @const { AppState } */
const STATE = {
    common_data: {
        __company: 'ego-visa',
        __platform: 'android',
        __app_version: '10',
        device_id: 's10',
        getaways_tile_type: 'getaways_cuckoo',
        __destination_id_viator: '',
        __destination_id_ego: '',
        currency: 'AED',
        __include_alcohol_offers: false,
        destination_iso_code: '',
        __language: 'en',
        device_model: 'S9',
        latitude: '31.505498499635145',
        longitude: '74.34634865611137',
    },
    __t:'',
    is_searching: false,
    previous_page: '/home',
    is_menu_visible: false,
    loader: false,
    connectivity: true
};

export default STATE;
