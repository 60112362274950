// @vue/component
import IconDirection from '@/assets/images/ic_direction.png'
import PlanBanner from '@/assets/images/plnpic-bnr-img.jpg'
import MapWithMarkers2 from "@/components/Maps/MapWithMarkers/MapWithMarkers2";
export default {
    name: 'POIImages',
    components: {
        MapWithMarkers2
    },
    mixins: [],
    props: {
        location: {
            type: Object
        }
    },
    data () {
        return {
            IconDirection,
            PlanBanner,
            dialog: true
        }
    },
    computed: {},
    watch: {},
    created () {},
    methods: {}
}
