import AddressIcon from '@/assets/images/address-icon.png'
import AddressIconBrand from '@/assets/images/address-icon-brand.png'
import ListingIcon from '@/assets/images/listing-icons.png'
import ListingIconBrand from '@/assets/images/listing-icon-brand.png'
import LikeIcon from '@/assets/images/like-icon.png'
import LikeIconBrand from '@/assets/images/like-icon-brand.png'
// @vue/component
export default {
    name: 'Tickets',

    components: {},

    mixins: [],

    props: {},

    data () {
        return {
            AddressIcon,
            AddressIconBrand,
            ListingIcon,
            ListingIconBrand,
            LikeIcon,
            LikeIconBrand,
            active_key: null
        }
    },

    computed: {},

    watch: {},

    created () {},

    methods: {
        swipe(key) {
            this.active_key = key;
        }
    }
}
