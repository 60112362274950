import {
    SET_DATA,
    SET_DESTINATION_DATA,
    SET_PREVIOUS_PAGE,
    SET_SEARCH_STATE,
    SET_MENU_STATE,
    SET_LOADER_STATE, SET_CONNECTIVITY_STATE, SET_TOKEN
} from './mutation-types';
/**
*
* @param { function } commit
* @param { Object } data
*/
export function setData({ commit }, data) {
    commit(SET_DATA, data);
}

export function set_menu_state({commit}, data){
    commit(SET_MENU_STATE, data);
}

export function set_connectivity({commit}, data){
    commit(SET_CONNECTIVITY_STATE, data)
}
export function set_token({commit}, data){
    commit(SET_TOKEN, data)
}
/**
 *
 * @param commit
 * @param { Object } status
 */
export function set_search_state({commit}, status) {
    commit(SET_SEARCH_STATE, status)
}

export function set_destination_data({commit}, data){
    commit(SET_DESTINATION_DATA, data);
}

/**
 *
 * @param commit
 * @param page
 */
export function set_previous_page({commit}, page){
    commit(SET_PREVIOUS_PAGE, page);
}

export function set_loader({commit}, loader){
    commit(SET_LOADER_STATE, loader)
}