<template>
<div></div>
</template>


<script>

import commonMixins from "../../mixins/commonMixins";

export default {
  name: "Categories",

  props: {
    filters: {
      type: Object,
    },
  },
  mixins:[commonMixins],

  mounted() {

  },
  data() {
    return {


    }
  },
  methods: {

  },
}
</script>

