<template>
  <div>
    <div class="planBlock">

      <label>{{ plan.title }}</label>

      <div class="plan-timing">
        <span>{{ plan.sights }}</span>
        <span> <img src="../../assets/images/pln-clock-icon.png" alt=""> {{ plan.expected_time }} </span>
      </div>
      <div class="plnBlock-tp-sec">
        <div class="plnBlock-tp-lft left">
          <span>
            <v-image height="120" class="img_responsive" :src="plan.main_image" alt="#"></v-image>
          </span>
        </div>
        <div class="plnBlock-tp-right right ">
          <span>
            <MapWithMarkers :zoom="7" hw="height: 120px; width: 250px" :outlets="plan.outlets"></MapWithMarkers>
          </span>
        </div>
      </div>
      <div class="plnBlock-btm-sec">
        <VueSlickCarousel v-bind="settings3">
          <div class="plnBlock-slid" v-for="(sub_image, i) in plan.sub_images" :key="i">
            <span v-ripple>
              <v-image ratio="2" class="img_responsive" :src="sub_image" alt=""  ></v-image>
            </span>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
    <p>{{ plan.footer_desc }}</p>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import MapWithMarkers from "@/components/Maps/MapWithMarkers/MapWithMarkers";
export default {
  name: "RecommendedPlans",
  props: ['plan'],
  components: {
    MapWithMarkers,
    VueSlickCarousel
  },
  data() {
    return {
      settings3: {
        "ltr": true,
        "dots": false,
        "autoplay": false,
        "dotsClass": "slick-dots slider-dots",
        "edgeFriction": 0.0,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "arrow":true,
        "touchThreshold": 15,
      },
    }
  },
}
</script>
