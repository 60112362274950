// @vue/component
import commonMixins from "@/mixins/commonMixins";

export default {
    name: 'Seamless',

    components: {},

    mixins: [commonMixins],

    props: {},

    data () {
        return {}
    },

    computed: {
        common_data() {
            return this.get_form_data()
        },
        common_auth_data(){
            return this.get_form_data_with_auth()
        },
        selected_destination(){
            return this.$store.getters['dashboard/get_selected_destination']
        },
        user(){
            return this.$store.getters['auth/getUser']
        },
        auth_data(){
            return this.$store.getters['auth/auth_data']
        }

    },

    watch: {},

    async created() {
        await this.$store.dispatch('auth/refresh_session')
        await this.$store.dispatch('backbutton/reset_back_button')
        this.log('Session Refreshed', 'success')
        let sessionToken = this.$route.query;
        const token = this.$store.getters['auth/getToken'];
        if (!token) {
            await this.$store.dispatch('auth/token');
        }
        if (!this.user){
            this.$store.dispatch('app/set_token', sessionToken['__t']);
            this.get_user_data(sessionToken)
        }



    },

    methods: {
        get_user_data(token){
            this.$store.dispatch('auth/get_user_details', token)
                .then(() => {
                    this.get_profile_data()
                })
        },
        get_destinations(){
            this.$store.dispatch('dashboard/get_destinations', this.common_data)
                .then(() => {
                    this.$router.push('/dashboard');

                })
                .catch(error => {
                    console.log(error)
                })
        },
        get_profile_data(){
            let headers = this.common_auth_data;
            this.$store.dispatch('auth/get_profile_data', headers)
                .then(() => {
                    if (!this.selected_destination) {
                        this.get_destinations();
                    } else {
                        this.get_home_data();
                    }
                })
        },
        get_home_data(){
            this.$store.dispatch('dashboard/get_home_data', this.common_auth_data)
                .then(() => {
                    this.$router.push('/home')
                })
                .catch(error => {
                    console.log(error)
                });
        },
    }
}
