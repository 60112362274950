/**
*
* 
* @param { AuthState } state
* @return { string }
*/
export function getUser(state) {
    return state.user;
}

/**
 *
 * @param { AuthState } state
 * @returns { string }
 */
export function getToken(state){
    return state.token;
}


export function getTickets(state){
    return state.tickets;
}

export function auth_data(state){
    return state.auth_data;
}