<template>
  <div>
    <GmapMap
        :center="center ? center : {lat: 25.121617, lng: 55.1815415}"
        :zoom="zoom ? zoom : 12"
        :options="{ scrollWheel:false, zoomControl:false, scaleControl:false, streetViewControl:false, }"
        :style="hw"
        ref="mapRef"
    >
      <GmapPolyline v-bind:path.sync="paths" v-bind:options="{ strokeColor:'#015a6e'}">
      </GmapPolyline>
      <GmapMarker
          :key="index"
          v-for="(marker, index) in outlets"
          :position="getProperty(marker)"
          :clickable="true"
          :draggable="false"
          :icon="marker_type === 'dynamic' ? `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${index + 1}|1a1f71|FFFFFF` : pinOptions"
      >
      </GmapMarker>
    </GmapMap>
</div>
</template>

<script>
import PinIcon from '@/assets/images/pin_active_10.png'
export default {
  name: "MapWithMarkers",
  props: ['outlets', 'hw','marker_type', 'zoom','center'],
  data() {
    return {
      pinOptions: {
        url: PinIcon,
        size: {width: 60, height: 90, f: 'px', b: 'px',},
        scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
      },
      paths: []
    }
  },
  watch: {
    center: function(new_center){
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(new_center)
      })
    }
  },
  methods: {
    getProperty(item) {
      if (Object.hasOwnProperty.call(item,'latitude') && Object.hasOwnProperty.call(item,'longitude')) {
        return { lat: item.latitude, lng: item.longitude }
      } else if(Object.hasOwnProperty.call(item,'lat') && Object.hasOwnProperty.call(item,'lng')) {
        return { lat: item.lat, lng: item.lng }
      } else if (Object.hasOwnProperty.call(item.geometry)){
        return { lat: item.geometry.location.lat, lng: item.geometry.location.lng }
      } else if (item.geometry){
        return { lat: item.geometry.location.lat(), lng: item.geometry.location.lng() }
      }
    }
  },
  beforeMount() {
    for (let item in this.outlets){
      let path = {
        lat: this.outlets[item].lat,
        lng: this.outlets[item].lng
      }
      this.paths.push(path)
    }
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      map.panTo(this.getProperty(this.outlets[0]))
    })
  }
}
</script>

<style scoped>
.vue-map-container,
.vue-map-container .vue-map {
  width: 100%;
  height: 100%;
}
</style>