// @vue/component
import RatingBar from "@/components/ExperienceDetailSections/RatingBar";
import commonMixins from "../../mixins/commonMixins";
export default {
    name: 'MerchantReview',

    components: {
        RatingBar
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {}
    },

    computed: {
        review_listing: function () {
            return this.$store.getters['deal/tour_reviews']
        },
        reviews(){
            return this.get_section('reviews')
        },
        deal_details(){
            return this.$store.getters["deal/deal_detail"]
        },
        selected_deal(){
            return this.$store.getters["dashboard/get_selected_experience"]
        },
    },

    watch: {},

     created() {
        this.getReviews()
    },

    methods: {
        get_section(identifier){
            let sections = this.deal_details.details;
            if (!sections)
                return
            for (let i = 0; i < sections.length; i++) {
                if (sections[i].section_identifier === identifier)
                    return sections[i];
            }
        },
        async getReviews() {
            let common_data = this.get_form_data()
            common_data.set('data_source', this.selected_deal.data_source);
            common_data.set('product_code', this.selected_deal.item_code);
            common_data.set('item_id', this.selected_deal.item_id);
            common_data.set('item_type', this.selected_deal.item_type);
            common_data.set('offset', 0);
            common_data.set('sort_type', 1)
            await this.$store.dispatch('deal/getTourReviews', common_data)
        }

    }
}
