<template>
  <div v-touch="{ left: () => swipe('left'), right: () => swipe('right') }" :class="is_delete_visible ? 'cr-plnlist-otr cr-plnlist-ext cr-plnlist-del' : 'cr-plnlist-otr cr-plnlist-ext'">
    <span @click="is_delete_visible = true" class="left btn cr-plnlist-minus "> <img class="img_responsive" src="../../assets/images/minus-icon.png" alt="#"> </span>
    <div class="cr-plan-list">
      <span v-if="item && mode === 'create'" class="">
        <img v-if="item.image" :src="item.image" alt="#" />
        <img v-else-if="item.image_url" :src="item.image_url" alt="#" />
        <img v-else-if="item.hero_urls" :src="item.hero_urls[0]" alt="#" />
      </span>
      <span v-else-if="item" class="">
        <img v-if="item.image" :src="item.image" alt="#" />
        <img v-else-if="item.image_url" :src="item.image_url" alt="#" />
        <img v-else-if="item.hero_urls" :src="item.hero_urls[0]" alt="#" />
      </span>
      <div class="cr-plnlst-txt-hldr ">
        <h2 v-if="item.title">{{ item.title }}</h2>
        <h2 v-else-if="item.name">{{ item.name }}</h2>
        <h2 v-else-if="item.display_name">{{ item.display_name }}</h2>
      </div>

    </div>
    <span id="handle" class="right btn cr-thumb-icon"> <img class="img_responsive" src="../../assets/images/pln-thumb-icon.png" alt="#"> </span>
    <strong @click="removeItem(item)" class="btn cr-plnlist-delet"> Delete </strong>
  </div>
</template>

<script>
export default {
  name: "PlanListItem",
  props: ['item', 'mode'],
  data() {
    return {
      is_delete_visible: false
    }
  },
  methods: {
    removeItem: function(item){
      this.$store.dispatch('planner/remove_from_plan_draft', item);
      this.is_delete_visible = false;
    },
    swipe(direction) {
      if (direction === 'left')
        this.is_delete_visible = true;
      if (direction === 'right')
        this.is_delete_visible = false;
    }
  },
}
</script>

<style scoped>

</style>