import {
    ADD_TO_PLAN_DRAFT,
    SET_RECOMMENDED_PLANS,
    REMOVE_FROM_PLAN_DRAFT,
    PUSH_TO_PLAN_DRAFT,
    RESET_PLAN_DRAFT, SET_SELECTED_PLAN, SET_PLAN_DETAILS
} from "./mutation-types";
import Repository from "@/repositories/RepositoryFactory"
const plannerRepo  =   Repository.get("planner")
export function get_recommended_plans({commit}, payload){
    return  new Promise((resolve, reject) => {
        plannerRepo.
        get_recommended_plans(payload)
            .then(resp => resp.data)
            .then(resp => {
                let data = resp.data
                commit(SET_RECOMMENDED_PLANS, data)
                resolve(data)
            })
            .catch(err => reject(err))
    })

}
export function delete_plan(_, payload){
    return new Promise(((resolve, reject) => {
        plannerRepo.
        delete_day_plan(payload)
            .then(resp => resp.data)
            .then(resp => {
                resolve(resp)
            }).catch(err => reject(err))
    }))
}

export function add_to_plan_draft({commit}, payload){
    commit(ADD_TO_PLAN_DRAFT, payload)
}

export function push_to_plan_draft({commit}, payload){
    if (payload)
        commit(PUSH_TO_PLAN_DRAFT, payload)
}

export function remove_from_plan_draft({commit}, payload){
    commit(REMOVE_FROM_PLAN_DRAFT, payload)
}
export function empty_plan_draft({commit}){
    commit(RESET_PLAN_DRAFT)
}
export function set_selected_plan({commit}, plan){
    commit(SET_SELECTED_PLAN, plan)
}
export function get_plan_details({commit}, payload){
    return new Promise((resolve, reject) => {
        plannerRepo
            .get_plan_details(payload)
            .then(resp => resp.data)
            .then(resp => {
                let data = resp.data
                commit(SET_PLAN_DETAILS, data)
                resolve(resp)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function create_day_plan(_,payload){
    return new Promise((resolve, reject) => {
        plannerRepo
            .create_day_plan(payload)
            .then(resp => resp.data)
            .then(resp => {
                resolve(resp)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export function update_day_plan(_,payload){
    return new Promise((resolve, reject) => {
        plannerRepo
            .update_day_plan(payload)
            .then(resp => resp.data)
            .then(resp => {
                resolve(resp)
            })
            .catch(error => {
                reject(error)
            })
    })
}