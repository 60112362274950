<template>
  <div class="pageview active-screen no-internet-pg Hdr-bg-none">
    <header class="Header_outer">
    </header>
    <div class="pg-content no-offer-view no-internet full_size_div ">
      <div class="no-offer-view-inner">
        <h3>Sign in failed</h3>
        <p>We were unable to sign you in. <br>You will be redirected shortly.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unauthenticated"
}
</script>

<style scoped>

</style>