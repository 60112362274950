<template>
   <span @click="$emit('click')" class="filter_btn" >
     <img :src="img" alt="Filter">
     <router-link v-if="url" :to="url"><mark>{{ title }}</mark></router-link>
     <a href="javascript:void(0)" v-else><mark>{{ title }}</mark></a>
     <b class="num" v-if="badge">{{ badge }}</b>
   </span>
</template>

<script>
export default {
  name: "GradientButton",
  props: {
    img: String,
    title: String,
    badge: Number,
    url: String
  },
}
</script>

<style scoped>

</style>