// @vue/component
import GuideBookButton from "@/components/CustomButtons/GuideBookButton";
import $ from 'jquery'
import commonMixins from "../../mixins/commonMixins";
export default {
    name: 'Guidebook',

    components: {
        GuideBookButton
    },

    mixins: [commonMixins],

    props: {},

    data () {
        return {
        }
    },

    computed: {
        guidebooks: function(){
            return this.$store.getters['guidebook/guidebooks']
        },
        what_to_do: function(){
            return this.get_section(1)
        },
        introduction: function(){
            return this.get_section(0);
        },
        where_to_eat: function (){
            return this.get_section(2)
        }

    },

    watch: {},

    created () {
        this.get_guide_books()
    },
    mounted() {
        $("#content").scroll( function () {
            let scroll = $("div#content").scrollTop();
            if(scroll > 5){
                $("#hdr-top").removeClass('Hdr-bg-none');
            }else{

                $("#hdr-top").addClass('Hdr-bg-none');
            }
        });
    },

    methods: {
        get_guide_books(){
            let commonData = this.get_form_data()
            commonData.set('is_guidebook_v2', true)
            this.$store.dispatch('guidebook/get_all_guides', commonData)
        },
        get_section(name){
            let sections = this.guidebooks;
            sections = sections['guidebook'];
            if (!sections)
                return null;
            return sections.find((section) => {
                if (section.section_identifier === name)
                    return section;
            });
        },
        get_menu_section(name){
            let sections = this.guidebooks;
            if (!sections)
                return null;
            sections = sections['guidebook_menu'];
            return sections.find((section) => {
                if (section.menu_identifier === name)
                    return section;
            });
        },
    }
}
