<template>
  <div v-if="loaded" class="full_size_div">
    <div v-scroll.self="onScrollAttractions" id="attractions_container" v-if="attractions && attractions.length > 0" class="thing-common-class overflow-auto full_size_div d-block">
      <div v-for="(attraction, index) in attractions" :key="index">
        <ActivityCard v-if="is_planner" subtype="planner" v-on:clicked="move_to_detail"  :data="attraction" type="attraction"></ActivityCard>
        <ActivityCard v-else v-on:clicked="move_to_detail"  :data="attraction" type="attraction"></ActivityCard>
      </div>
    </div>
    <!-- No-Offer Screen / Component -->
    <div v-if="attractions && attractions.length <= 0" class=" no-offer-view ">
      <NoOfferComponent></NoOfferComponent>
    </div>
    <!-- Start Bottom Navigation Bar -->
    <div class="scr-btm-overlay">
      <BottomNavigationBar v-if="attractions && attractions.length > 0">
        <GradientButton @click.native="move_to_map"  title="Map" :img="MapIcon"></GradientButton>
      </BottomNavigationBar>
      <div v-if="is_planner" class="scr-btm-Buttons btm-full-btn">
        <router-link to="/planner/create-plan" class="buttons gredient2"><span> View Day Plan: {{ plan_draft_count }} Items selected  </span></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import BottomNavigationBar from "../../components/BottomNavigationBar/BottomNavigationBar";
import GradientButton from "@/components/GradientButton";
import ActivityCard from "../../components/ActivityCard/ActivityCard";
import MapIcon from '../../assets/images/map-icon.png'
import NoOfferComponent from "@/components/NoOfferComponent/NoOfferComponent";
import commonMixins from "../../mixins/commonMixins";
export default {
  name: "Attractions",
  components: {
    BottomNavigationBar,
    GradientButton,
    ActivityCard,
    NoOfferComponent

  },
  data() {
    return {
      loaded: false,
      MapIcon: MapIcon,
      offset: 0,
      deal_type: 'attractions',
      category_id: 9,
      page_size: 10,
      sort_by: 'sort_by_near_by',
      attractions_data: null
    }
  },
  mixins: [commonMixins],
  computed: {
    activeFilterCount: function (){
      return this.$store.getters['filters/get_active_filters_count']
    },
    attractions() {
      return this.attractions_data ? this.attractions_data.things_to_do : null;
    },
    is_planner: function(){
      return this.$route.name === 'planner-activities' || this.$route.name === 'planner-attractions';
    },
    plan_draft_count: function (){
      return this.$store.getters['planner/plan_draft'].length;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.get_list();
    })
  },
  methods: {
    move_to_map: function(){
      this.$store.dispatch('dashboard/set_attractions', this.attractions_data)
      if (this.is_planner)
        this.$router.push('/map-view/planner')
      else
        this.$router.push('/map-view')
    },
    onScrollAttractions () {
      let obj = $('#attractions_container')
      if( obj.scrollTop() >= (obj[0].scrollHeight - obj[0].offsetHeight)) {
        this.offset += 10;
        this.get_list()
      }
    },
    set_attractions: function(attractions){
      if (!this.attractions_data)
        this.attractions_data = attractions;
      if (this.attractions_data.things_to_do && attractions.things_to_do){
        this.attractions_data.things_to_do = [...this.attractions_data.things_to_do, ...attractions.things_to_do]
      }
      else
        this.attractions_data = attractions;
    },
    get_list(){
      let common_data = this.get_form_data()
      common_data.set('offset', this.offset);
      common_data.set('sort_by', this.sort_by);
      common_data.set('category_id', this.category_id);
      common_data.set('page_size', this.page_size);
      if (this.presetFilters && Object.hasOwnProperty.call(this.presetFilters,'api_param_name')){
        common_data.set(this.presetFilters.api_param_name, this.presetFilters.key)
        let filters = JSON.parse(JSON.stringify(this.activeFilters));
        filters[this.presetFilters.api_param_name] = [];
        filters[this.presetFilters.api_param_name].push(this.presetFilters)
        this.$store.dispatch('filters/set_active_filters', filters)
        //  TODO replace this in case more than one preset filters added
        this.$store.dispatch('filters/set_active_filters_count', 1)
        this.$store.dispatch('filters/reset_preset_filters');
      } else {
        this.$store.dispatch('filters/set_active_filters_count', 0)
        common_data.set('deal_type', this.deal_type);
      }
      this
          .$store
          .dispatch('dashboard/get_things_to_do', common_data)
          .then(data => {
            this.set_attractions(data)
            this.loaded = true;
          })
          .catch(error => {
            console.log(error)
          }).finally(() => {
        this.loaded = true;
      });
    }
  },
}
</script>

<style scoped>

</style>