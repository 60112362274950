<template>
  <div>
    <div  class="listingBlock">
      <ul  class="cityList">
        <li @click="set_selected_city(city)" v-ripple v-for="(city, i) in cities" :key="i">
          <span class="title ">{{ city.name }}</span>
          <span class="text-right" style="float: right">{{ city.country }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cities: Array
  },
  methods: {
    set_selected_city(city) {
      this.$store.dispatch('dashboard/set_selected_destination', city);
      this.$router.push('/home');
    }
  },
};
</script>
