/**
*
* 
* @param { AppState } state
* @return {Object}
*/
export function getCommonData(state) {
    return state.common_data;
}
export function get_menu_state(state){
    return state.is_menu_visible;
}
export function token(state){
    return state.__t;
}

export function get_connectivity(state){
    return !state.connectivity;
}

/**
 * @param { AppState } state
 * @return {Object}
 */
export function get_search_status(state) {
    return state.is_searching;
}

/**
 *
 * @param state
 * @returns String
 */
export function get_previous_page(state) {
    return state.previous_page;
}

export function loader(state){
    return state.loader;
}