<template>
  <div class=" menu-list-wrap hdr-gredient">
    <div class="menu-listing overflow-auto">
      <ul>
        <li> <router-link class="active" to="/home">Home </router-link> </li>
        <li> <router-link to="/planner">Planner </router-link> </li>
        <li> <router-link to="/guidebook">Guidebook </router-link> </li>
        <li> <router-link to="/tickets">Tickets </router-link> </li>
      </ul>
      <ul class="cont-link">
        <li> <a :href="get_section('help').url">Help</a></li>
        <li> <a :href="get_section('eul').url">End User License Agreement </a> </li>
        <li> <a :href="get_section('terms_of_use').url">Terms of Use </a> </li>
        <li> <a :href="get_section('privacy_policy').url">Privacy Policy </a> </li>
      </ul>
    </div>
    <div  class="menu-btm-sec">
      <span  class="menu-close-btn">
        <img v-ripple @click="$store.dispatch('app/set_menu_state')" :src="closeButton" />
      </span>
    </div>
  </div>

</template>

<script>
    import closeButton from '@/assets/images/menu-close-btn.png'
    export default {
      props: {

      },
      methods: {
        get_section(name){
          if (!this.user)
            return;
          let sections = this.user.info_section;
          if (!sections)
            return null;
          return sections.find((section) => {
            if (section.uid === name)
              return section;
          });
        },
      },
      computed: {
        user: function(){
          return this.$store.getters['auth/getUser']
        }
      },
      data() {
        return {
          closeButton: closeButton
      }
      },
    };
</script>
