import { SET_PAGES } from "@/store/modules/backbutton/mutation-types";
export function get_previous_page(){

    return false;
}

// eslint-disable-next-line no-unused-vars
export function push_to_pages({commit,state}, payload){

    if(!payload || payload.meta.backRestricted )
        return;
    let pages = JSON.parse(JSON.stringify(state.pages));

    let alreadyExist = pages[pages.length - 1];
    if (alreadyExist && alreadyExist.path === payload.path)
        return;

    if(pages.length > 0 ) {
        let last_page = pages[pages.length - 1];
        delete last_page.current;
    }
    let page = {
        id: pages.length,
        name: payload.name,
        path: payload.path,
        current: true
    }

    pages.push(page)

    commit(SET_PAGES, pages)
}

export function pluck_last_page({commit, state}) {
    let pages = JSON.parse(JSON.stringify(state.pages));

    if (pages.length <= 1)
        return;
    pages.splice(-1, 1)
    pages[pages.length - 1].current = true;
    commit(SET_PAGES, pages)
}

export function reset_back_button({commit}){
    commit(SET_PAGES, [])
}