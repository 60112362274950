<template>
  <div v-if="plans" class="dayplan-detail dayplan-cmn-cls  d-block">

    <div v-if="plans.info" @click="$router.replace('/planner/select-plan')" class="plabe-info">
      <span class="eff-on-tab"> <img src="../../assets/images/plus-icon.png" alt="#"> </span>
      <h2 v-if="user_plans && user_plans.length <= 0">{{ plans.info.create_plan_title }}</h2>
      <h2 v-else>Add New Plan</h2>
      <p>
        {{ plans.info.create_plan_message  }}
      </p>
    </div>

    <div v-if="user_plans && user_plans.length > 0" class="plansWrap">
      <span class="rec-plan"> Yours Plans </span>
      <div @click="move_to_plan_detail(plan)" v-for="(plan, i) in user_plans" :key="i" >
        <RecommendedPlans :plan="plan"></RecommendedPlans>
      </div>
    </div>
    <div v-if="recommended_plans && recommended_plans.length > 0" class="plansWrap">
      <span class="rec-plan"> Recommended Plans </span>
      <div @click="move_to_plan_detail(plan)" v-for="(plan, i) in recommended_plans" :key="i">
        <RecommendedPlans  :plan="plan"></RecommendedPlans>
      </div>
    </div>
  </div>
</template>
<script>
import RecommendedPlans from "@/components/RecommendedPlans/RecommendedPlans";
import commonMixins from "../../mixins/commonMixins";
export default {
  name: "DayPlan",
  components: {
    RecommendedPlans
  },
  mixins: [commonMixins],
  methods: {
    move_to_plan_detail(plan){
      this.$store.dispatch('planner/set_selected_plan', plan)
      this.$router.push('/planner/detail')
    }
  },
  computed: {
    plans: function (){
      return this.$store.getters['planner/recommended_plans']
    },
    recommended_plans: function (){
      return this.plans.recommended_plans;
    },
    user_plans: function (){
      return this.plans.plans;
    }
  }
}
</script>

<style scoped>

</style>