<template>
  <div>
    <a href="#" @click="is_active = !is_active" class="gd-book-btm-icon">
      <img src="../../assets/images/ic-gd-book-FAB.png" alt="#" />
    </a>
    <div  v-if="is_active" class="popup-outer">
      <div @click="is_active = !is_active" class="pop_inner guid-bk-popup">
        <div class="futhor-opt">
          <ul class="">
            <li v-for="(button, i) in buttons" :key="i" @click="buttonAction(button)">
              <a href="javascript:void(0)">
                <img v-if="button.menu_icon_url" :src="button.menu_icon_url" alt="#" />
                {{ button.menu_title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: "GuideBookButton",
  props: ['buttons'],
  data() {
    return {
      is_active: false
    }
  },
  methods: {
    buttonAction(button){
      if (button.action_type === 'scroll'){
        $('#'+button.action_value)[0].scrollIntoView()
      }
      if (button.action_type === "tab"){
        this.$router.push(`/${button.action_value}`)
      }

    },

  },
}
</script>

<style scoped>

</style>