<template>
    <div class="no-offer-view-inner">
            <span class="offer-icon">
              <img src="../../assets/images/no-offer-icon.png" alt="no-offer-icon" />
            </span>
      <h3>It’s a little empty in here isn’t it?</h3>
      <p>
        Explore our offers and activities,
        and add your favourites to your wish
        list to see them here.
      </p>
    </div>
</template>

<script>
    export default {
        props: {
        },
    };
</script>
