const routes = [
    {
        path: '/',
        name: 'Seamless',
        component: () => import('../views/Seamless/Seamless'),
        meta: {
            backRestricted: true
        }
    },
    {
        path: '/unauthenticated',
        name: 'Unauthenticated',
        component: () => import('../views/Unauthenticated/Unauthenticated')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard/Dashboard'),
        meta: {
            backRestricted: true
        }
    },
    {
        path: '/home',
        name: 'HomeScreen',
        component: () => import('../views/HomeScreen/HomeScreen')
    },
    {
        path: '/outlet-list',
        name: 'OutletList',
        component: () => import('../views/OutletList/OutletList')
    },
    {
        path: '/outlet-detail',
        name: 'OutletDetail',
        component: () => import('../views/OutletDetail/OutletDetail')
    },
    {
        path: '/merchant-pin',
        name: 'MerchantPin',
        component: () => import('../views/MerchantPin/MerchantPin')
    },
    {
        path: '/redemption/:status',
        name: 'RedemptionStatus',
        component: () => import('../views/RedemptionComplete/RedemptionComplete')

    },
    {
        path: '/experience-listing',
        component: () => import('../views/ExperienceListing/ExperienceListing'),
        props: true,
        children: [{
            path: "activities",
            component: () => import('../components/ExperienceListingComponents/Activities'),
        },{
            path: "attractions",
            component: () => import('../components/ExperienceListingComponents/Attractions')
        }]
    },
    {
        path: '/map-view/:type?',
        name: 'ExperienceListingMapView',
        component: () => import('../views/ExperienceListingMapView/ExperienceListingMapView'),
        meta: {
            backRestricted: true
        }
    },
    {
        path: '/add-by-search/:category_id?/:deal_type?/:type?',
        name: 'AddBySearch',
        component: () => import('../views/AddBySearch/AddBySearch'),
    },
    {
        path: '/experience-detail/:id',
        name: 'ExperienceDetail',
        component: () => import('../views/ExperienceDetail/ExperienceDetail')
    },
    {
        path: '/merchant-review',
        name: 'MerchantReview',
        component: () => import('../views/MerchantReview/MerchantReview')
    },
    {
        path: '/filters',
        name: 'Filters',
        component: () => import('../views/Filters/Filters'),
        meta: {
            backRestricted: true
        }
    },
    {
        path: '/guidebook',
        name: 'Guidebook',
        component: () => import('../views/Guidebook/Guidebook')
    },
    {
        path: '/tickets',
        name: 'Tickets',
        component: () => import('../views/Tickets/Tickets')
    },
    {
        path: '/planner/select-plan',
        name: 'SelectPlan',
        component: () => import('../views/Planner/SelectPlan/SelectPlan')
    },
    {
        path: '/planner/create-plan/:mode?',
        name: 'CreatePlan',
        component: () => import('../views/Planner/CreatePlan/CreatePlan')
    },
    {
        path: '/planner',
        name: 'DayPlanLanding',
        component: () => import('../views/Planner/DayPlanLanding/DayPlanLanding')
    },
    {
        path: '/planner/poi-build',
        name: 'POIBuild',
        component: () =>  import('../views/Planner/POIBuild/POIBuild')
    },
    {
        path: '/planner/poi-images',
        name: 'POIImages',
        component: () => import('../views/Planner/POIImages/POIImages')
    },
    {
        path: '/planner/map-view',
        name: 'CreateDPlanMapView',
        component: () => import('../views/CreateDayPlanMapView/CreateDayPlanMapView')
    },
    {
        path: '/planner/wishlist-items',
        name: 'WishlistItems',
        component: () => import('../views/Planner/WishlistItems/WishlistItems')
    },
    {
        path: '/planner-view',
        component: () => import('../views/ExperienceListing/ExperienceListing'),
        props: true,
        children: [{
            path: "activities",
            name: 'planner-activities',
            component: () => import('../components/ExperienceListingComponents/Activities'),
        },{
            path: "attractions",
            name: 'planner-attractions',
            component: () => import('../components/ExperienceListingComponents/Attractions'),

        }]
    },
    {
        path: '/planner/detail',
        name: 'PlannerDetail',
        component: () => import('../views/Planner/Details/Details')
    },
  ]
  export default routes;
