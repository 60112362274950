import {SET_ACTIVE_FILTERS_COUNT, SET_DIALOG_STATUS, SET_FILTERS, SET_PRESET_FILTERS} from './mutation-types';
export function set_active_filters_count({commit}, count){
    commit(SET_ACTIVE_FILTERS_COUNT, count);
}
export function set_dialog_status({commit}, status){
    commit(SET_DIALOG_STATUS, status);
}
export function set_active_filters({commit}, filters){
    commit(SET_FILTERS, filters)
}
export function reset_filters({commit}){
    commit(SET_FILTERS, {})
    commit(SET_DIALOG_STATUS, false)
    commit(SET_ACTIVE_FILTERS_COUNT, 0)
}
export function set_preset_filters({commit}, filters){
    commit(SET_PRESET_FILTERS, filters)
}
export function reset_preset_filters({commit}){
    commit(SET_PRESET_FILTERS, null)
}