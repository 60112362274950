import Client from './clients/axios-client'

const resource = '/deals-v'


export default {
    get_deal_detail(payload){
        return Client.post(`${resource}/get-deal-detail`, payload)
    },
    get_tour_reviews(payload){
        return Client.post(`${resource}/get-tour-reviews`,payload)
    }
}