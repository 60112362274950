<template>
  <div class="bookingInfo ">
    <div class="iconHead">
      <img src="../../assets/images/booking-info-icon.png" class="img_responsive" alt="icon">
    </div>
    <h2>{{ booking.section_title }}</h2>
    <ul class="checkList">
      <li v-for="(feature, index) in booking.booking_features" :key="index">{{ feature }}</li>
    </ul>
    <h2 >{{ booking.booking_helpline_details.help_message }} {{ booking.booking_helpline_details.contact_message }} </h2>
    <div class="callInfo">
      <span class="num ">{{ booking.booking_helpline_details.contact_number }} </span>
    </div>
  </div>
</template>

<script>
    export default {
        props: {
          booking: Object
        },
    };
</script>
