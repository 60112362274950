<template>
  <div class="hd-searching-sec">
    <div class="topSearch left-btn">
      <form>
        <div class="fieldWrap">
          <GmapAutocomplete @place_changed="setPlace" :options="{fields: ['geometry', 'address_components']}"></GmapAutocomplete>
          <v-btn class="search-btn" type="submit"> </v-btn>
        </div>
      </form>
      <router-link :to="$store.getters['app/get_previous_page']" class="cancel back-arrow"  type="button" name="button">Cancel</router-link>

      <ul class="search-listing overflow-auto ">
        <li v-for="(item, i) in items" :key="i"> <a href="#" v-ripple> {{ item }} </a> </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Autocomplete",
  data() {
    return {
      items: [],
      place: ''
    }
  },
  methods: {
    setPlace(place) {
      this.place = place
    },
  },
}
</script>

<style scoped>

</style>