<template>
  <div class="info-det-list">
    <h2 @click="togglePanel" :class="state ? 'info-det-title active' : 'info-det-title'">{{ title }}</h2> <!-- "active" Add Class on Click -->
    <div :id="panel_id" class="info-list-detail"><!-- Add Style Display:block; as Default -->
      <slot></slot>
    </div>
  </div>

</template>

<script>
import $ from 'jquery'
export default {
  name: "ExpandablePanel",
  props: ['title', 'panel_id'],
  data() {
    return {
      state: false
    }
  },
  methods: {
    togglePanel() {
      this.state = !this.state
      if (this.state)
        $(`#${this.panel_id}`).show(300)
      else
        $(`#${this.panel_id}`).hide(300)
    }
  },
}
</script>

<style scoped>
</style>