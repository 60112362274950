import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import vuexLocal from "./vuexLocal";

const debug = process.env.NODE_ENV !== 'production';

import auth from './modules/auth'
import app from './modules/app'
import dashboard from './modules/dashboard'
import deal from './modules/deal'
import filters from './modules/filters'
import wishlist from './modules/wishlist'
import planner from './modules/planner'
import guidebook from './modules/guidebook'
import backbutton from './modules/backbutton'

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        auth,
        app,
        dashboard,
        deal,
        filters,
        wishlist,
        planner,
        guidebook,
        backbutton
    },

    strict: debug,
    plugins: debug ? [createLogger, vuexLocal] : [vuexLocal]
})


export default store;