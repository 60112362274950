<template>
  <div class="pageview mar-review-page screen-hdr1  active-screen">
    <header class="Header_outer">
      <div class="hdr-title-sec ">
        <ul class="hdr-lft-btns">
          <li> <v-btn href="#" class="back-arrow"> </v-btn></li>
        </ul>
        <h2 class="screen-title">Reviews</h2>
      </div>
    </header>
    <div class="pg-content march-reviews-content full_size_div overflow-auto ">
      <div class="reviewsHead">
        <div class="left rating-wrap others">
          <p class="reviewTotal  ">1</p>
          <div class="rating">
            <span class="rating_star" style="width:95%"></span>
          </div>
        </div>
        <div class="right progress-wrap">
          <div class="progress">
            <div class="progress-inner-wrap">
              <div class="progress-bar">
                <div class="progress-bar__secondary" style="width: 0%;"></div>
                <div class="progress-bar__primary" style="width: 100%;"></div>
              </div>
              <b class="progress-status">100</b>
            </div>
            <div class="progress-inner-wrap">
              <div class="progress-bar ">
                <div class="progress-bar__secondary" style="width: 0%;"></div>
                <div class="progress-bar__primary" style="width: 80%;"></div>
              </div>
              <b class="progress-status">80</b>
            </div>
            <div class="progress-inner-wrap">
              <div class="progress-bar">
                <div class="progress-bar__secondary" style="width: 0%;"></div>
                <div class="progress-bar__primary" style="width: 50%;"></div>
              </div>
              <b class="progress-status">50</b>
            </div>
            <div class="progress-inner-wrap">
              <div class="progress-bar">
                <div class="progress-bar__secondary" style="width: 0%;"></div>
                <div class="progress-bar__primary" style="width: 30%;"></div>
              </div>
              <b class="progress-status">30</b>
            </div>
            <div class="progress-inner-wrap">
              <div class="progress-bar">
                <div class="progress-bar__secondary" style="width: 0%;"></div>
                <div class="progress-bar__primary" style="width: 10%;"></div>
              </div>
              <b class="progress-status">10</b>
            </div>
          </div>
        </div>
      </div>
      <button class="btn highlow-rating-btn" type="button" name="button">Highest - Lowest Rating</button>
      <div class="customer-reviews-List">
        <ul class="reviewList">
          <li class="">
            <div class="head">
              <div class="name-date-wrap">
                <span class="name left"> michaelmorton </span>
                <span class="date right">February 2018</span>
              </div>
              <div class="rating">
                <span class="rating_star" style="width: 100%;"></span>
              </div>
            </div>
            <p >It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
          </li>
          <li class="">
            <div class="head">
              <div class="name-date-wrap">
                <span class="name left"> michaelmorton </span>
                <span class="date right">February 2018</span>
              </div>
              <div class="rating">
                <span class="rating_star" style="width: 100%;"></span>
              </div>
            </div>
            <p >It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
          </li>
        </ul>
        <a href="#" class="mor-rev-btn gredient2">More Reviews </a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "MerchantReviews"
}
</script>

<style scoped>

</style>