<template>
  <li class="">
    <v-btn @click="$store.dispatch('app/set_menu_state')" href="javascript:void(0)" x-small class="menu-icon"> </v-btn>
  </li>
</template>

<script>
export default {
  name: "MenuButton"
}
</script>
<style scoped>

</style>