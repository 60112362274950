<template>
  <v-img  class="grey lighten-2" :src="getHttpsUrl(src)" :alt="alt" :lazy-src="Placeholder" :height="height ? height : ''" :aspect-ratio="ratio"></v-img>
</template>

<script>
import Placeholder from '@/assets/images/placeholder-material.png'
export default {
  props: {
    src: String,
    ratio: null,
    alt: null,
    height: null
  },
  methods: {
    getHttpsUrl(url) {
      if (url && url.includes('http')) {
        return url.replace('http://', 'https://');
      }
      return url;
    }
  },
  data() {
    return {
      Placeholder
    }
  },
    };
</script>
