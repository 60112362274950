import {SET_USER, SET_TOKEN, SET_TICKETS, SET_AUTH_DATA,} from './mutation-types';
import Repository from "../../../repositories/RepositoryFactory"
const AuthRepo  =   Repository.get("auth")

export function refresh_session({commit}){
    commit(SET_USER, null)
    commit(SET_TOKEN, null)
    commit(SET_AUTH_DATA, null)
}

export async function get_tickets_data({commit}, payload) {
    let resp = await AuthRepo.get_ticket_data(payload)
    let data = resp.data;
    data = data.data.my_tickets;
    commit(SET_TICKETS, data);
}

export function get_user_details({commit}, token) {

    return new Promise((resolve, reject) => {
        AuthRepo
            .get_user_details(token)
            .then(resp  => resp.data)
            .then(resp => {
                if (!resp.__visid || !resp.__visst){
                    window.location.href = location.origin + '/unauthenticated?close-view=true';
                } else {
                    let auth_data = {
                        __session_token: resp.__visst,
                        __user_id : resp.__visid,
                        __session_id: resp.__vissid
                    }
                    commit(SET_AUTH_DATA, auth_data)
                }
                resolve(resp)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export async function get_profile_data({commit}, payload) {
    let resp = await AuthRepo
        .get_profile_data_p(payload)
    let data = resp.data.data
    commit(SET_USER, data)
}
export function refresh_session_token({commit,rootGetters: rootGetters}) {
    let session_token = rootGetters['app/token'];
    return new Promise((resolve, reject) => {
        let token = {
            __t : session_token
        }
        AuthRepo
            .get_user_details(token)
            .then(resp  => resp.data)
            .then(resp => {
                let auth_data = {
                    __session_token: resp.__visst,
                    __user_id : resp.__visid,
                    __session_id: resp.__vissid
                }
                commit(SET_AUTH_DATA, auth_data)
                resolve(resp)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export async function cancel_ticket(_,payload){
    await AuthRepo.
        cancel_ticket(payload)

}
export async function token({commit}){
    let resp = await AuthRepo.auth_token()
    let token = 'Basic ' + atob(resp.data);
    commit(SET_TOKEN, token)
}