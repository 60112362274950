import Categories from "@/components/FilterTypes/Categories";

// @vue/component
export default {
    name: 'Filters',

    components: {
        Categories
    },

    mixins: [],

    props: {},

    data () {
        return {

        }
    },



    watch: {
    },
    computed: {
        category_filters: function (){
            return this.$store.getters['dashboard/get_filters']
        }
    },

    mounted() {
        this.get_filters()
    },

    methods: {
        get_filters: function (){
            let headers = this.$store.getters['app/getCommonData']
            headers.set('deal_type', 'tours')
            headers.set('category_id', 3)
            this.$store.dispatch('dashboard/get_filters', headers)
                .then(resp => {
                    console.log(resp)
                }).catch(error => {
                    console.error(error)
            })
        }
    }
}
