<template>
  <div>
    <span> <v-image ratio="2" :src="img" alt=""> </v-image></span>
    <div class="cover des_count_overlay">
      <div class="table">
        <div class="table-cell">
          <h2> {{ name }}
            <img :src="flag" alt="#">
          </h2>
          <span class="btm-logo">
            <img :src="Logo" alt="btm-logo">
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '../../assets/images/ent-des-logo.png'
    export default {
        props: {
          img: String,
          name: String,
          flag: String
        },
      data() {
        return {
          Logo
        }
      },
    };
</script>
