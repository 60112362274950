<template>
  <div  class="ticket-list hdr-gredient">
    <span @click="move_to_voucher_url" class="ticklist-img left"> <img  :src="ticket.image" alt="#">
      <mark>{{ ticket.ticket_status_text }}</mark>
    </span>
    <div class="ticklist-text right ">
      <h3 @click="move_to_voucher_url">{{ ticket.name }}</h3>
      <label>{{ ticket.descriptions }}</label>
      <p>{{ date_formatted(ticket.date) }} </p>
      <a href="javascript:void(0)" @click="alert= true;msg= 'Are you sure want to cancel this ticket?';type='dialog'" v-if="ticket.is_cancelable">Cancel </a>
    </div>
    <v-dialog v-model="alert">
      <v-card>
        <v-container>
          <v-card-text>
            <h3 class="text-center">
              {{ msg }}
            </h3>
          </v-card-text>
          <v-card-actions>
            <div v-if="type === 'alert'" class="scr-btm-Buttons btm-full-btn">
              <div @click="alert=false" class="buttons gredient2"><span> Done </span></div>
            </div>
            <div v-if="type === 'dialog'" class="scr-btm-Buttons btm-full-btn">
              <div @click="cancel_ticket" class="buttons gredient2"><span> Cancel </span></div>
            </div>
            <v-spacer></v-spacer>
            <div v-if="type === 'dialog'" class="scr-btm-Buttons btm-full-btn">
              <div @click="alert = false" class="buttons gredient2"><span> No </span></div>
            </div>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "Ticket",
  props: ['ticket'],
  data() {
    return {
      type: 'dialog',
      msg: null,
      alert: false
    }
  },
  methods: {
    date_formatted(date) {
      return moment(date).format('DD/MM/YY hh:mm')
    },
    cancel_ticket: async function () {
      this.$store.dispatch('app/set_loader', true)
      this.alert = false
      let order_number = this.ticket.order_number;
      order_number = btoa(order_number);
      this.$store.dispatch('auth/cancel_ticket', order_number).then(() => {
        this.type = 'alert';
        this.msg = 'Your ticket has been cancelled successfully.'
        this.alert = true;
      }).catch(() => {
        this.type = 'alert';
        this.msg = 'Sorry! there was an error cancelling your ticket.'
        this.alert = true;
      }).finally(() => {
        this.$store.dispatch('app/set_loader', false)
        this.$emit('update')
      });
      this.type = 'dialog';
      this.msg = null;
    },
    move_to_voucher_url: function(){
      this.$store.dispatch('app/set_loader', true)
      location.href = this.ticket.voucher_url;
    }
  },
}
</script>