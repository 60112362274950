export function recommended_plans(state){
    return state.recommended_plans;
}
export function plan_draft(state){
    return state.plan_draft;
}
export function selected_plan(state){
    return state.selected_plan;
}
export function plan_details(state){
    return state.plan_details;
}