// @vue/component
import NoOfferComponent from "../../../components/NoOfferComponent/NoOfferComponent";
import RecommendedPlans from "@/components/RecommendedPlans/RecommendedPlans";
import DayPlan from "@/components/RecommendedPlans/DayPlan";
import ActivityCard from '@/components/ActivityCard/ActivityCard'
import EmptyResultComponent from "@/components/NoOfferComponent/EmptyResultComponent";
import iconWishlist from '@/assets/images/ic-wishlistEmpty.png'
import commonMixins from "../../../mixins/commonMixins";
export default {
    name: 'DayPlanLanding',
    components: {
        NoOfferComponent,
        RecommendedPlans,
        DayPlan,
        ActivityCard,
        EmptyResultComponent
    },
    mixins: [commonMixins],
    props: {},
    data () {
        return {
            iconWishlist,
            settings1: {
                "ltr": true,
                "dots": false,
                "autoplay": false,
                "dotsClass": "slick-dots slider-dots",
                "edgeFriction": 0.0,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "arrow":true,
                "touchThreshold": 15,
            },
            settings2: {
                "ltr": true,
                "dots": false,
                "autoplay": false,
                "dotsClass": "slick-dots slider-dots",
                "edgeFriction": 0.0,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "arrow":true,
                "touchThreshold": 15,
            },
            settings4: {
                "ltr": true,
                "dots": false,
                "autoplay": false,
                "dotsClass": "slick-dots slider-dots",
                "edgeFriction": 0.0,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "arrow":true,
                "touchThreshold": 15,
            },
            tab: null
        }
    },
    computed: {
        wishlist: function(){
            return this.$store.getters['wishlist/wishlist_items']
        },
        auth_data: function(){
            return this.$store.getters['auth/auth_data']
        }
    },
    watch: {},
    created () {
        this.$store.dispatch('planner/empty_plan_draft')
        this.dispatch_plans()
    },
    methods: {
        move_to_experience_detail(experience) {
            this.$store.dispatch('dashboard/set_selected_experience', experience);
            if (experience.item_code)
                this.$router.push(`/experience-detail/${experience.item_code}`);
        },
        dispatch_plans: function (){
            let data = this.get_form_data()
            if (this.auth_data){
                data = this.get_form_data_with_auth()
            }
            this.$store.dispatch('planner/get_recommended_plans', data)
                .then(() => {})
                .catch(() => {})
        }
    }
}
