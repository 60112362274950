<template>
<div id="app" class="main-wrap">
    <v-app>
      <transition name="fade" appear appear-active-class="animated slideInDown">
        <router-view />
      </transition>
      <div v-if="loader" ref="_loader" class="popup-outer loader">
        <div class="pop_inner">
          <span class="loader-holder">
            <img src="./assets/images/page_loader_optim.gif" alt="#" />
          </span>
        </div>
      </div>
      <v-dialog
          hide-overlay
          transition="scale-transition" fullscreen  max-width="290" v-model="show_menu">
        <v-card>
          <Menu @close="show_menu = false"></Menu>
        </v-card>
      </v-dialog>
      <v-dialog v-model="is_connected" hide-overlay fullscreen>
        <NoInternet></NoInternet>
      </v-dialog>
    </v-app>
</div>
</template>
<script>
import Menu from "@/components/Menu/Menu";
import NoInternet from "@/components/NoInternet/NoInternet";
  export default {
  name: "App",
  metaInfo: {
    title: process.env.VUE_APP_TITLE,
    titleTemplate: '%s',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0,user-scalable=0' }
    ],
    link: [
      {
        rel: 'preconnect', href: 'https://s3.amazonaws.com'
      }
    ]
  },
  components: {
    NoInternet,
    Menu,
  },
  methods: {

  },
  computed: {
    show_menu: function (){
      return this.$store.getters['app/get_menu_state']
    },
    loader: function () {
      return this.$store.getters['app/loader']
    },
    is_connected: function(){
      return this.$store.getters['app/get_connectivity']
    }
  },
  }
</script>