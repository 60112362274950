
import MapWithMarkers from "@/components/Maps/MapWithMarkers/MapWithMarkers2";
import moment from 'moment';
import PlannerItemListingMapView from "@/components/PlannerItemListingMapView/PlannerItemListingMapView";
import icEdit from '@/assets/images/ic-edit.svg'
import icTrash from '@/assets/images/ic-trash.svg'
import commonMixins from "../../../mixins/commonMixins";
// @vue/component
export default {
    name: 'Details',

    components: {
        MapWithMarkers,
        PlannerItemListingMapView
    },


    mixins: [commonMixins],

    props: {},

    data () {
        return {
            showMapModal: false,
            icEdit,
            icTrash,
            alert: false,
        }
    },

    computed: {

        selected_plan: function (){
            return this.$store.getters['planner/selected_plan']
        },
        plan_details: function (){
            let details = this.$store.getters['planner/plan_details']
            if (details && details.plans)
                return details.plans[0]
            return null
        },
        user: function(){
            return this.$store.getters['auth/getUser']
        },
        belongs_to_user: function (){
            return !!(this.plan_details && this.plan_details.user_id === this.user.customer_profile.customer_id);
        }
    },

    watch: {},

    created () {
        let headers = this.get_form_data()
        headers.set('plan_id', this.selected_plan.id)
        this.$store.dispatch('planner/get_plan_details', headers)
    },

    methods: {
        move_to_experience_detail: function (item){
            this.$store.dispatch('dashboard/set_selected_experience', item)
            this.$router.push(`/experience-detail/${item.item_code}`)

        },
        deletePlan: function (){
            this.alert = false;
            let headers = this.get_form_data_with_auth()
            headers.set('plan_id', this.plan_details.id)
            this.$store.dispatch('planner/delete_plan', headers)
                .then(() =>{
                    this.$router.push('/planner')
                }).catch(error => { console.log(error)
            })
        },
        formatDate: function(date){
            return moment(date).format('dddd, MMMM DD, YYYY')
        }
    }
}
