<template>
  <div class=" pageview filters-page screen-hdr1 active-screen">

    <header class="Header_outer">
      <div class="hdr-title-sec">
        <ul class="hdr-lft-btns">
          <li @click="reset"> <a href="#" class=" text-btn ">Reset</a> </li>
        </ul>

        <h2 class="screen-title">Tour type</h2>

        <ul class="hdr-right-btns">
          <li @click="submit_pills"> <a href="#" class=" text-btn ">Done</a> </li>
        </ul>
      </div>
    </header>

    <div class="pg-content ticket-content overflow-auto">
      <div class="topSearch">
        <form  class="ng-pristine ng-valid">
          <div class="fieldWrap">
            <input type="search" v-model="term" @input="search_option" placeholder="Search">
            <button type="submit" class="search-btn"></button>
          </div>
        </form>
      </div>
      <div class="flt-tur-selected">
        <ul v-if="selected_pill && selected_pill.name">
          <li>
            <span class="flt-selected-list">
              {{ selected_pill.name }}
              <button @click="remove_pill(selected_pill)" class="btn" type="button" name="button"></button>
            </span>
          </li>
        </ul>
      </div>

      <div class="flt-tur-listing" v-if="!term">
        <ul>
          <li @click="set_selected(option)" v-for="(option, i) in options.options" :key="i"> <a href="#" :class="selected_pill === option ? 'flt-tur-list active' : 'flt-tur-list'"> {{ option.name }} </a> </li>
        </ul>
      </div>
      <div class="flt-tur-listing" v-else>
        <ul>
          <li @click="set_selected(option)" v-for="(option, i) in search_results" :key="i"> <a href="#" :class="selected_pill === option ? 'flt-tur-list active' : 'flt-tur-list'"> {{ option.name }} </a> </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: "Listing",
  props: ['options', 'selected_option', 'is_single'],
  data() {
    return {
      selected_pill: null,
      term: null,
      search_results: null
    }
  },
  watch: {
    selected_option: function () {
      this.selected_pill = null;
      this.search_results = null;
      this.term = null;
      this.selected_pill = this.selected_option ? this.selected_option : null;
    }
  },
  mounted() {
    this.selected_pill = null;
    this.search_results = null;
    this.term = null;
    this.selected_pill = this.selected_option;
  },
  methods: {
    reset: function (){
      this.term = null;
      this.search_results = null
      this.selected_pill = null;
    },
    search_option: function(){
      this.search_results = _.pickBy(this.options.options, o => {
          let term = _.toLower(this.term)
          let name = _.toLower(o.name)
          return name.search(term) >= 0
        });

    },
    set_selected: function (pill){
      this.selected_pill = pill
    },
    remove_pill: function (){
      this.selected_pill = null;
    },
    submit_pills() {
      this.$emit('close', this.selected_pill);
    }
  },

}
</script>

<style scoped>

</style>