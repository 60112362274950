import {
    SET_ACTIVITIES,
    SET_ATTRACTIONS,
    SET_DESTINATIONS_DATA,
    SET_FILTERS,
    SET_HOME_DATA,
    SET_SEARCH_PILLS,
    SET_SEARCH_RESULTS,
    SET_SEARCH_TERM,
    SET_SELECTED_DESTINATION,
    SET_SELECTED_EXPERIENCE
} from './mutation-types';

export default {
    /**
    *
    * @param { DashboardState } state
    * @param { Object } data
    */
    [SET_HOME_DATA](state, { data }) {
        state.home_data = data;
    },
    /**
     * @param { DashboardState } state
     * @param { Object } destinations
     */
    [SET_DESTINATIONS_DATA](state, destinations) {
        state.destinations = destinations;
    },
    /**
     * @param { DashboardState } state
     * @param { Object } destination
     */
    [SET_SELECTED_DESTINATION](state, destination){
        state.selected_destination = destination;
    },
    /**
     *
     * @param state
     * @param { Object } activities
     */
    [SET_ACTIVITIES](state, activities) {
        if (!state.activities)
            state.activities = activities;
        if (state.activities.things_to_do && activities.things_to_do){
            state.activities.things_to_do = [...state.activities.things_to_do, ...activities.things_to_do]
        }
        else
            state.activities = activities;
    },
    /**
     *
     * @param state
     * @param {Object} attractions
     */

    [SET_ATTRACTIONS](state, attractions){
        if (!state.attractions)
            state.attractions = attractions;
        if (state.attractions.things_to_do && attractions.things_to_do){
            state.attractions.things_to_do = [...state.attractions.things_to_do, ...attractions.things_to_do]
        }
        else
            state.attractions = attractions;
    },
    /**
     * @param state
     * @param results
     */
    [SET_SEARCH_RESULTS](state, results) {
        state.search_results = results;
    },
    /**
     * @param state
     * @param results
     */
    [SET_SEARCH_PILLS] (state, results) {
        state.pills = results;
    },
    /**
     *
     * @param state
     * @param selected_experience
     */
    [SET_SELECTED_EXPERIENCE] (state, selected_experience) {
        state.selected_experience = selected_experience;
    },
    /**
     *
     * @param state
     * @param filters
     */
    [SET_FILTERS] (state, filters) {
        state.filters = filters;
    },

    [SET_SEARCH_TERM] (state, search_term) {
        state.search_term = search_term;
    }



};
