import StarRating from "@/components/StarRating/StarRating";
import Stars from "@/components/StarRating/Stars";
import Image from "@/components/Image/Image"
import NoOfferComponent from "@/components/NoOfferComponent/NoOfferComponent";
import MenuButton from "@/components/CustomButtons/MenuButton";
import BackButton from "@/components/CustomButtons/BackButton";
import ReadMore from "@/components/ReadMore/ReadMore";
const GlobalComponents = {
    install(Vue) {
        Vue.component("stars", Stars);
        Vue.component("star-rating", StarRating);
        Vue.component("v-image", Image);
        Vue.component("no-offer", NoOfferComponent)
        Vue.component("menu-button", MenuButton)
        Vue.component("back-button", BackButton)
        Vue.component("read-more", ReadMore)
    }
}
export default GlobalComponents