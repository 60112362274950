import {
     SET_ATTRACTIONS,
    SET_DESTINATIONS_DATA,
    SET_HOME_DATA, SET_SEARCH_PILLS, SET_SEARCH_RESULTS,
    SET_SELECTED_DESTINATION, SET_SELECTED_EXPERIENCE,
    SET_FILTERS, SET_SEARCH_TERM
} from './mutation-types';
import Repository from "../../../repositories/RepositoryFactory";
import store from "../../index";
const HomeRepo = Repository.get('home');
/**
 * API call to get home data
 * @param { function } commit
 * @param payload
 * @param { string } data
 */
export function get_home_data({ commit }, payload) {
    return new Promise((resolve, reject) => {
        HomeRepo.
            get_home_data(payload)
            .then(resp => resp.data)
            .then(resp => {
                let data = resp.data;
                commit(SET_HOME_DATA, { data });
                resolve(resp);
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * Commits a selected destination to store once
 * it's been selected by user
 * @param commit
 * @param payload
 */
export function set_selected_destination({ commit }, payload) {

    commit(SET_SELECTED_DESTINATION, payload);
    commit(SET_ATTRACTIONS, {});
    commit(SET_ATTRACTIONS, {});
    commit(SET_HOME_DATA, {});
    store.dispatch('app/set_destination_data', payload);
}

export function set_selected_experience({commit}, payload) {
    commit(SET_SELECTED_EXPERIENCE, payload);
}


/**
 * API call to get all destinations data
 * @param commit
 * @param payload
 * @return {Promise<unknown>}
 */
export function get_destinations({commit}, payload) {
    return new Promise((resolve, reject) => {
        HomeRepo.
            get_destinations(payload)
            .then(resp => resp.data)
            .then(resp => {
                commit(SET_DESTINATIONS_DATA, resp.data);
                resolve(resp);
            })
            .catch(error => {
                reject(error)
            })

    })
}
export function empty_search_results({commit}){
    commit(SET_SEARCH_RESULTS, {});
    commit(SET_SEARCH_PILLS, []);
}

export function set_attractions({commit}, payload) {
    commit(SET_ATTRACTIONS, payload);
}

/**
 *
 * @param _
 * @param payload
 * @returns {Promise<unknown>}
 */

export function get_things_to_do(_, payload){
    return new Promise((resolve, reject) => {
        HomeRepo
            .get_things_to_do_list(payload)
            .then(resp => resp.data)
            .then(resp => {
                let data = resp.data;
                // if (data.things_to_do_type === 'tours')
                //     commit(SET_ACTIVITIES, data);
                // else
                //     commit(SET_ATTRACTIONS, data)
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * @param commit
 * @param payload
 * @returns {Promise<unknown>}
 */
export function get_home_outlets({commit}, payload){
    let category = payload.get('category_id');
    return new Promise((resolve, reject) => {
        HomeRepo
            .get_home_outlets(payload)
            .then(resp => resp.data)
            .then(resp => {
                let data = resp.data
                commit(SET_SEARCH_RESULTS, data)
                if (!category)
                    commit(SET_SEARCH_PILLS, data.categories);
                resolve(resp)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export async function get_filters({commit}, payload){
    let resp = await HomeRepo.get_filters(payload)
    let data = resp.data;
    data = data.data;
    commit(SET_FILTERS, data)
}

export function set_search_term({commit}, term){
    commit(SET_SEARCH_TERM, term)
}